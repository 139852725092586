import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TypographyProps
} from '@mui/material'
import { ExpandLess, NavigateNext } from '@mui/icons-material'
import React, { useContext } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import useFeatures from '@utils/hooks/useFeatures'
import { MenuItem } from './config'
import { GrUpgrade } from 'react-icons/gr'
import SidebarContext from '@utils/contexts/SidebarContext'

const isExternal = (path: string) => path.startsWith('http')

export type SidebarItemProps = {
  item: MenuItem
  className?: string
  open?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  current?: boolean
  main?: boolean
  nested?: boolean
}

const SidebarItem = ({
  item,
  open,
  setOpen,
  main = false,
  nested = false
}: SidebarItemProps) => {
  const { title, Icon, path, children, feature, showAncestor = true } = item
  const { pathname } = useLocation()
  const { isSidebarOpen } = useContext(SidebarContext)
  const { canUseFeature } = useFeatures()
  const external = isExternal(item.path)
  const isCurrent = pathname === item.path
  const isAncestor = pathname.startsWith(item.path) && !isCurrent
  const canUse = feature ? canUseFeature(feature) : true

  const clickHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()

    if (external) {
      return window.open(`${path}`, '_blank', 'noopener')
    }

    if (!canUse) {
      return navigate('/upgrade/', { state: { feature } })
    }

    return navigate(path)
  }

  const blockedStyles: TypographyProps['sx'] = !canUse
    ? {
        position: 'absolute',
        transform: 'translateX(-1.5rem)',
        color: 'secondary.main',

        '& path': {
          stroke: 'currentcolor'
        }
      }
    : {}

  return (
    <ListItem
      button
      onClick={clickHandler}
      sx={{
        pl: nested ? 9 : 3,
        alignItems: 'center',
        color: () =>
          isCurrent || (isAncestor && showAncestor)
            ? 'primary.main'
            : 'text.primary'
      }}
      data-cy={`sidebarLink ${path}`}
    >
      {canUse && Icon && (
        <ListItemIcon
          sx={{ minWidth: (theme) => theme.spacing(6), color: 'currentColor' }}
        >
          {Icon}
        </ListItemIcon>
      )}
      {!canUse && (
        <ListItemIcon
          sx={{
            minWidth: (theme) => theme.spacing(6),
            ...blockedStyles
          }}
        >
          <GrUpgrade />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          sx: {
            fontSize: () => (main ? '1rem' : '0.875rem')
          }
        }}
        primary={title}
      />
      {children && isSidebarOpen && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            size="small"
            aria-label={`${title} menu`}
            data-cy={`sidebarToggleChildren ${path}`}
            onClick={() => setOpen && setOpen(!open)}
          >
            {open ? <ExpandLess /> : <NavigateNext />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

export default SidebarItem
