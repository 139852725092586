export type TTactic = {
  id: string
  name: string
  description: string
  reference: string
}

export const MITRE_TACTIC_SITE = 'https://attack.mitre.org/tactics'

export const MITRE_TACTIC_DICTIONARY: { [key: string]: TTactic } = {
  TA0043: {
    id: 'TA0043',
    reference: `${MITRE_TACTIC_SITE}/TA0043/`,
    name: 'Reconnaissance',
    description:
      'The adversary is trying to gather information they can use to plan future operations.'
  },
  TA0042: {
    id: 'TA0042',
    reference: `${MITRE_TACTIC_SITE}/TA0042/`,
    name: 'Resource Development',
    description:
      'The adversary is trying to establish resources they can use to support operations.'
  },
  TA0001: {
    id: 'TA0001',
    reference: `${MITRE_TACTIC_SITE}/TA0001/`,
    name: 'Initial Access',
    description: 'The adversary is trying to get into your network.'
  },
  TA0002: {
    id: 'TA0002',
    reference: `${MITRE_TACTIC_SITE}/TA0002/`,
    name: 'Execution',
    description: 'The adversary is trying to run malicious code.'
  },
  TA0003: {
    id: 'TA0003',
    reference: `${MITRE_TACTIC_SITE}/TA0003/`,
    name: 'Persistence',
    description: 'The adversary is trying to maintain their foothold.'
  },
  TA0004: {
    id: 'TA0004',
    reference: `${MITRE_TACTIC_SITE}/TA0004/`,
    name: 'Privilege Escalation',
    description: 'The adversary is trying to gain higher-level permissions.'
  },
  TA0005: {
    id: 'TA0005',
    reference: `${MITRE_TACTIC_SITE}/TA0005/`,
    name: 'Defense Evasion',
    description: 'The adversary is trying to avoid being detected.'
  },
  TA0006: {
    id: 'TA0006',
    reference: `${MITRE_TACTIC_SITE}/TA0006/`,
    name: 'Credential Access',
    description: 'The adversary is trying to steal account names and passwords.'
  },
  TA0007: {
    id: 'TA0007',
    reference: `${MITRE_TACTIC_SITE}/TA0007/`,
    name: 'Discovery',
    description: 'The adversary is trying to figure out your environment.'
  },
  TA0008: {
    id: 'TA0008',
    reference: `${MITRE_TACTIC_SITE}/TA0008/`,
    name: 'Lateral Movement',
    description: 'The adversary is trying to move through your environment.'
  },
  TA0009: {
    id: 'TA0009',
    reference: `${MITRE_TACTIC_SITE}/TA0009/`,
    name: 'Collection',
    description:
      'The adversary is trying to gather data of interest to their goal.'
  },
  TA0011: {
    id: 'TA0011',
    reference: `${MITRE_TACTIC_SITE}/TA0011/`,
    name: 'Command and Control',
    description:
      'The adversary is trying to communicate with compromised systems to control them.'
  },
  TA0010: {
    id: 'TA0010',
    reference: `${MITRE_TACTIC_SITE}/TA0010/`,
    name: 'Exfiltration',
    description: 'The adversary is trying to steal data.'
  },
  TA0040: {
    id: 'TA0040',
    reference: `${MITRE_TACTIC_SITE}/TA0040/`,
    name: 'Impact',
    description:
      'The adversary is trying to manipulate, interrupt, or destroy your systems and data.'
  }
}
