import useForm from '@utils/hooks/useForm'
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  Stack
} from '@mui/material'
import React, { ReactElement, useEffect } from 'react'

type Props = SelectProps & {
  id: string
  options: {
    value: string | number
    label: string
  }[]
  form: ReturnType<typeof useForm>
  helperText?: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function MultiSelect({
  id,
  options,
  form,
  required,
  helperText,
  ...selectConfig
}: Props): ReactElement {
  const [data, setData] = React.useState<(string | number)[]>(form.values[id])

  useEffect(() => {
    form.setFieldValue(id, data)
  }, [data])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setData(event.target.value as string[])
  }

  return (
    <FormControl
      error={form.errors[id]}
      required={required}
      sx={{
        minWidth: '100%',
        '&:not(:last-child)': {
          marginBottom: 3
        }
      }}
      variant="outlined"
    >
      <InputLabel shrink={true} id={`${id}-label`}>
        {selectConfig.label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}`}
        label={selectConfig.label}
        multiple
        fullWidth
        inputProps={{
          sx: { p: 5 }
        }}
        variant="outlined"
        value={data}
        onChange={handleChange}
        MenuProps={MenuProps}
        renderValue={(selected) => (
          <Stack direction="row" spacing={1}>
            {(selected as string[]).map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Stack>
        )}
        {...selectConfig}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={data.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default MultiSelect
