import { Tab, Tabs } from '@mui/material'
import { TabPanel } from './TabPanel'

export function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export { Tabs, Tab, TabPanel }
