import ReactMarkdown from 'markdown-to-jsx'
import { Box, BoxProps, Typography } from '@mui/material'
import { Link } from 'gatsby-theme-material-ui'
import { useTheme } from '@emotion/react'
import React, { useEffect } from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-json';

function MarkdownListItem(props: BoxProps<'li'>) {
  return (
    <Typography component="li" variant="body2" sx={{ mt: 1 }} {...props} />
  )
}


function MarkdownDataDefinition(props: BoxProps<'li'>) {
  return (
    <Typography component="dd" variant="body2" sx={{ mt: 1 }} {...props} />
  )
}

function Code(props: any) {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  return (
    <code {...props } />
   
  )
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h1',
        component: 'h1'
      }
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h2', component: 'h2' }
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h3', component: 'h3' }
    },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h4', component: 'h4' }
    },
    p: {
      component: Typography,
      props: { paragraph: true, variant: 'body2' }
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem
    },
    dd: {
      component: MarkdownDataDefinition
    },
    code: {
      component: Code
    }
  }
}

export default function Markdown(props: any) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        '& pre': {
          maxWidth: '100%',
          overflowX: 'auto',
          minWidth: 0,
          whiteSpace: 'break-spaces',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          padding: 4,
        },

        '& pre.language-json': {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: (theme) =>  theme.palette.divider,
          fontSize: '0.875rem'
        },

        '& .property': {
          color: 'secondary.main'
        },

        '& .string': {
          color: 'text.secondary'
        },

        '& .number': {
          color: 'warning.dark'
        },

        '& .boolean': {
          color: 'warning.dark'
        },

        '& dt': {
          ...theme.typography['subtitle1'],
          fontFamily: 'monospace, monospace'
        },
        '& dd': {
          marginLeft: 0,
          mb: 1
        }
      }}
    >
      <ReactMarkdown options={options} {...props} />
    </Box>
  )
}
