import { GridLinkOperator, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { GenericTablePageProps } from '@templates/Generic/Table'
import moment from 'moment'
import { HostResponse, HostsResponse } from './api'

export const hostPresets: GenericTablePageProps['gridOptions']['presets'] = [
  {
    label: 'Active Only',
    filter: {
      items: [
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 1
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Inactive Only',
    filter: {
      items: [
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'false',
          id: 1
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  }
]

export const isHostOffline = (
  hostData: HostsResponse['data'] | GridValueGetterParams['row']
): boolean => {
  const lastSeen = moment(hostData.date_last_seen)

  const threshold = moment().subtract(24, 'hours')

  return lastSeen.isBefore(threshold)
}

export const isHostUnknown = (
  hostData: HostsResponse['data'] | GridValueGetterParams['row']
): boolean => !hostData.active

export const getStatusPropsFromResponse = (response: HostResponse['data']) => {
  if (!response) {
    return {
      isUnknown: true,
      isActive: false,
      hasResults: false,
      hasPasses: false,
      hasAlerts: false,
      hasErrors: false,
      isAuthenticated: false,
      isOffline: true
    }
  }

  const {
    active,
    date_last_seen,
    authentication_status,
    results: { total, alert, error, pass }
  } = response

  return {
    isUnknown: !date_last_seen || date_last_seen == '0001-01-01T00:00:00Z',
    isActive: active,
    hasResults: total > 0,
    hasAlerts: alert > 0,
    hasErrors: error > 0,
    hasPasses: pass > 0,
    isAuthenticated: authentication_status == 'ok',
    isOffline:
      !date_last_seen ||
      moment(date_last_seen).isBefore(moment().subtract(24, 'hours'))
  }
}
