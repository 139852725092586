import { Avatar, Badge, Divider, Menu, MenuItem, styled } from '@mui/material'
import { Settings } from '@mui/icons-material'
import { User } from '@utils/api'
import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { IconButton } from 'gatsby-theme-material-ui'

interface Props {
  user: User | null
}

export const MenuItemLink = styled(Link)({
  color: 'currentColor',
  textDecoration: 'none'
})

export default function AppBarAccount({ user }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        color="inherit"
        size={'small'}
        aria-controls="account-menu"
        aria-haspopup="true"
        aria-label="Toggle Account Menu"
        onClick={handleClick}
        key="account-button"
      >
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={<Settings sx={{ width: 22, height: 22 }} />}
        >
          <Avatar>{user ? user.username.charAt(0).toUpperCase() : '?'}</Avatar>
        </Badge>
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <MenuItemLink to="/my-account/">My Account</MenuItemLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MenuItemLink to="/settings/">Settings</MenuItemLink>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <MenuItemLink to="/logout/">Logout</MenuItemLink>
        </MenuItem>
      </Menu>
    </div>
  )
}
