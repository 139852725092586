import {
  Assignment,
  CalendarToday,
  ContactSupport,
  Dashboard,
  Dns,
  Feedback,
  MenuBook,
  NotInterested,
  Settings,
  TrackChanges
} from '@mui/icons-material'
import Sandfly from '@svgs/icon-mini.svg'
import { TFeatureKey } from '@utils/api'
import React, { ReactElement } from 'react'

export type MenuItem = {
  title: string
  path: string
  Icon?: ReactElement
  children?: MenuItem[]
  feature?: TFeatureKey
  showAncestor?: boolean
}

const mainItems: MenuItem[] = [
  {
    title: 'Dashboard',
    path: '/dashboard/',
    Icon: <Dashboard fontSize="small" />
  },
  {
    title: 'Results',
    path: '/results/',
    Icon: <Assignment fontSize="small" />,
    children: [
      {
        title: 'Results by Host',
        path: '/results/',
        showAncestor: false
      },
      {
        title: 'All Results',
        path: '/results/all/'
      }
    ]
  },
  {
    title: 'Hosts',
    path: '/hosts/',
    Icon: <Dns fontSize="small" />,
    children: [
      {
        title: 'Credentials',
        path: '/hosts/credentials/',
        showAncestor: false
      },
      {
        title: 'Jump Hosts',
        path: '/hosts/jump-hosts/',
        feature: 'jump_hosts',
        showAncestor: false
      },
      {
        title: 'Add Host',
        path: '/hosts/add/'
      },
      {
        title: 'Add Credential',
        path: '/hosts/credentials/add/'
      },
      {
        title: 'Add Jump Host',
        path: '/hosts/jump-hosts/add/',
        feature: 'jump_hosts'
      }
    ]
  },
  {
    title: 'Scanning',
    path: '/scan/',
    Icon: <TrackChanges fontSize="small" />,
    children: [
      {
        title: 'New Manual Scan',
        path: '/scan/',
        showAncestor: false
      },
      {
        title: 'Scanning Queue',
        path: '/scan/queue/'
      },
      {
        title: 'Scanning Error Log',
        path: '/scan/errors/'
      }
    ]
  },
  {
    title: 'Scheduler',
    path: '/scheduler/',
    Icon: <CalendarToday fontSize="small" />,
    children: [
      {
        title: 'Add New Schedule',
        path: '/scheduler/add/',
        feature: 'schedules'
      }
    ]
  },
  // {
  //   title: 'Reports',
  //   path: '/reports/',
  //   Icon: <Assessment fontSize="small" />
  // },
  {
    title: 'Sandflies',
    path: '/sandflies/',
    Icon: <Sandfly fontSize="small" />,
    children: [
      {
        title: 'Add Custom Sandfly',
        path: '/sandflies/add/',
        feature: 'custom_sandflies'
      }
    ]
  },
  {
    title: 'Whitelists',
    path: '/whitelists/',
    Icon: <NotInterested fontSize="small" />
  },
  // {
  //   title: 'Forensic Playbook',
  //   path: '/forensic-playbook/',
  //   Icon: <Book fontSize="small" />
  // },
  {
    title: 'Settings',
    path: '/settings/',
    Icon: <Settings fontSize="small" />,
    children: [
      {
        title: 'License',
        path: '/settings/license/'
      },
      {
        title: 'Manage Users',
        path: '/settings/users/'
      },
      {
        title: 'Audit Logs',
        path: '/settings/audit-log/'
      },
      {
        title: 'Manage Notifications',
        path: '/settings/notifications/'
      },
      {
        title: 'Server Configuration',
        path: '/settings/server-configuration/'
      }
    ]
  }
]

const secondaryItems: MenuItem[] = [
  {
    title: 'Reference Docs',
    path: 'https://support.sandflysecurity.com/',
    Icon: <MenuBook fontSize="small" />
  },
  {
    title: 'Emergency Support',
    path: 'https://www.sandflysecurity.com/contact-us/',
    Icon: <ContactSupport fontSize="small" />
  },
  {
    title: 'Provide Feedback',
    path: 'https://www.sandflysecurity.com/contact-us/',
    Icon: <Feedback fontSize="small" />
  }
]

const config = {
  mainItems,
  secondaryItems
}

export default config
