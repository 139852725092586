import { Paper, PaperProps, alpha } from '@mui/material'
import { getBackgroundColor } from '@utils/theme'
import React, { FC } from 'react'

export type SurfaceProps = PaperProps & {
  color?: 'error' | 'success' | 'warning' | 'default' | false
  disabled?: boolean
  tintActionButtons?: boolean
}

const Surface: FC<SurfaceProps> = ({
  children,
  color,
  disabled,
  tintActionButtons = true,
  sx,
  ...rest
}) => {
  return (
    <Paper
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.divider,
        overflow: 'hidden',
        height: '100%',
        transition: 'background-color 500ms ease',
        backdropFilter: 'blur(16px) saturate(180%)',
        bgcolor: ({ palette }) =>
          color && color !== 'default'
            ? getBackgroundColor(palette[color].main, palette.mode)
            : alpha(palette.background.paper, 0.75),

        ...(color && color !== 'default' && tintActionButtons
          ? {
              '& .MuiChip-root': {
                fontWeight: 700,
                color: ({ palette }) => palette[color].main,
                backgroundColor: ({ palette }) =>
                  getBackgroundColor(palette[color].main, palette.mode),
                borderColor: 'currentColor'
              },
              '& a.MuiLink-underlineHover, & .result-status': {
                color: ({ palette }) => palette[color].main
              },
              '&& .MuiChip-icon': {
                color: 'currentColor'
              }
            }
          : {}),
        ...(disabled
          ? {
              color: 'text.disabled',

              '& .MuiChip-root': {
                fontWeight: 700,
                color: ({ palette }) => palette.text.disabled,
                backgroundColor: ({ palette }) =>
                  getBackgroundColor(palette.text.disabled, palette.mode),
                borderColor: 'currentColor'
              },
              '& a.MuiLink-underlineHover, & .result-status': {
                color: ({ palette }) => palette.text.disabled
              },
              '&& .MuiChip-icon': {
                color: 'currentColor'
              }
            }
          : {}),

        ...sx
      }}
      {...rest}
    >
      {children}
    </Paper>
  )
}

export default Surface
