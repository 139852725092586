import React, { ReactElement } from 'react'
import { Box, Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material'
import { DEFAULT_DASH_PATH } from '@utils/auth'
import { Link } from 'gatsby-theme-material-ui'

interface Props {
  items: BreadcrumbsItem[]
}

export type BreadcrumbsItem = {
  title: string
  to?: string
}

export default function Breadcrumbs({ items }: Props): ReactElement {
  return (
    <Box
      mt={1}
      mb={4}
      sx={{
        '& a': {
          textDecoration: 'none'
        }
      }}
    >
      <MUIBreadcrumbs maxItems={4} aria-label="breadcrumb">
        <Link key={`breadcrumb-dash`} to={DEFAULT_DASH_PATH}>
          {'Dashboard'}
        </Link>
        {items &&
          items.map((item) =>
            item.to ? (
              <Link key={`breadcrumb-${item.title}`} to={item.to}>
                {item.title}
              </Link>
            ) : (
              <Typography
                key={`breadcrumb-${item.title}`}
                aria-current="page"
                color="inherit"
              >
                {item.title}
              </Typography>
            )
          )}
      </MUIBreadcrumbs>
    </Box>
  )
}
