import React from 'react'
// import { makeStyles } from '@mui/styles'
import { useForm } from '@mantine/hooks'
import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material'
import useApi from '@utils/hooks/useApi'
import { getHostTags, TagsResponse } from '@utils/api'

type Props = TextFieldProps & {
  id: string
  label?: string
  form: ReturnType<typeof useForm>
  disabled?: boolean
}

export default function TagField({
  required = false,
  helperText,
  disabled = false,
  ...props
}: Props) {
  let { id, label, form, ...other } = props

  if (!label) {
    label = id.charAt(0).toUpperCase() + id.slice(1)
  }

  const { response } = useApi<TagsResponse>({
    apiMethod: getHostTags
  })

  const options: readonly string[] = response
    ? response.map(({ tag }) => tag)
    : []

  const [inputValue, setInputValue] = React.useState('')

  const onChangeHandler: any = (_event: any, newValue: string | null) => {
    form.setFieldValue(id, newValue as string | null)
  }

  return (
    <Autocomplete
      id={id}
      multiple
      options={options}
      value={form.values[id]}
      onChange={onChangeHandler}
      inputValue={inputValue}
      autoSelect
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      disabled={disabled}
      freeSolo
      renderTags={(value: unknown[], getTagProps) =>
        value.map((option: unknown, index: number) => (
          <Chip
            variant="outlined"
            sx={{ my: 1, mx: 0.5 }}
            label={option as string}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          helperText={helperText}
          variant="outlined"
          label={label}
          {...other}
          sx={{
            width: '100%'
          }}
        />
      )}
    />
  )
}
