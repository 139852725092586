import React, { ReactElement, useContext } from 'react'
import {
  AppBar as MiuAppBar,
  Box,
  Divider,
  NoSsr,
  Stack,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material'
import Logo from '@svgs/icon-lofi.svg'
import { DEFAULT_DASH_PATH } from '@utils/auth'
import AppBarAccount from './AppBarAccount'
import { Clock, Button } from '@components'
import { HiLightBulb, HiOutlineLightBulb } from 'react-icons/hi'
import { GrUpgrade } from 'react-icons/gr'
import useLicense from '@utils/hooks/useLicense'
import { getUpgradeUrl } from '@utils/license'
import { graphql, useStaticQuery } from 'gatsby'
import AuthContext from '@utils/contexts/AuthContext'
import ColorModeContext from '@utils/contexts/ColorModeContext'
import { IconButton } from 'gatsby-theme-material-ui'
import { ThemeProvider } from '@emotion/react'
import { dark } from '@utils/theme'
import AppBarQueueButton from './AppBarQueueButton'

interface Props {}

export default function AppBar({}: Props): ReactElement {
  const { isTrial = false } = useLicense()
  const { user } = useContext(AuthContext)
  const { toggleColorMode } = useContext(ColorModeContext)
  const theme = useTheme()
  const handleThemeChange = () => {
    toggleColorMode()
  }
  // const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          version
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={dark}>
      <MiuAppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'common.black',
          color: 'common.white'
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  m: 'auto !important',
                  height: (theme) => theme.spacing(4)
                }}
              />
            }
          >
            <IconButton
              key="logo-button"
              to={DEFAULT_DASH_PATH}
              edge="start"
              color="primary"
              aria-label="menu"
              size="large"
              sx={{ ml: -2.75 }}
            >
              <Logo style={{ color: 'currentColor' }} />
            </IconButton>

            <Box>
              <Typography variant="subtitle1" sx={{ lineHeight: 1, m: 0 }}>
                {'Sandfly Security'}
              </Typography>
              <Typography variant="body2">{`Version ${data.site.siteMetadata.version}`}</Typography>
            </Box>
          </Stack>

          <Stack
            marginLeft="auto"
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              data-cy="scanNowButton"
              color="inherit"
              to={'/scan/'}
              variant="outlined"
            >
              {'Scan Now'}
            </Button>

            <AppBarQueueButton>{'Task Queue'}</AppBarQueueButton>

            <NoSsr>
              {isTrial && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    window.open(
                      getUpgradeUrl({ componentName: 'Upgrade Header Prompt' }),
                      '_blank',
                      'noopener'
                    )
                  }
                  startIcon={<GrUpgrade size={16} className="stroke-reset" />}
                >
                  {'Upgrade'}
                </Button>
              )}
            </NoSsr>

            <Clock />

            {/* // TODO: Make notification
            <AppBarNotifications
              notifications={[
                {
                  id: 'test',
                  title: 'Test Notification',
                  children:
                    'Anim eu elit nulla officia laborum labore duis ut anim velit Lorem aute et.',
                  severity: 'info'
                }
              ]}
            /> */}

            <IconButton
              color="inherit"
              onClick={handleThemeChange}
              key="darkmode-button"
              size="large"
            >
              {theme.palette.mode === 'light' ? (
                <HiOutlineLightBulb />
              ) : (
                <HiLightBulb />
              )}
            </IconButton>

            <AppBarAccount user={user} />
          </Stack>
        </Toolbar>
      </MiuAppBar>
    </ThemeProvider>
  )
}
