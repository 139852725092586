import { Button as MuiButton } from 'gatsby-theme-material-ui'
import { GrUpgrade } from 'react-icons/gr'
import React, { FC, ReactElement } from 'react'
import useFeatures from '@utils/hooks/useFeatures'
import { navigate } from 'gatsby'
import { TFeatureKey } from '@utils/api'
import { Tooltip } from '@mui/material'

type Props = React.ComponentProps<typeof MuiButton> & {
  restricted?: boolean
  feature?: TFeatureKey
}

export default function Button({
  restricted = false,
  feature,
  className,
  children,
  to,
  ...props
}: Props): ReactElement {
  const { canUseFeature } = useFeatures()
  const canUse = feature ? canUseFeature(feature) : !restricted
  const sx = {
    '&.MuiButton-containedSecondary': {
      color: 'common.white'
    },

    '& .MuiButton-startIcon path, & .MuiButton-endIcon path': {
      stroke: 'currentColor'
    },

    '& .button__restricted-icon ': {
      color: 'secondary.main',
      marginRight: '0.5rem',

      '& path': {
        stroke: 'currentColor'
      }
    },

    ...(!canUse && {
      '&.MuiButton-contained': {
        color: 'text.disabled',
        backgroundColor: 'divider',
        boxShadow: 'none'
      },
      '&.MuiButton-outlined': {
        color: 'text.disabled',
        borderColor: 'divider'
      }
    }),
    ...(props.sx && props.sx)
  }
  return (
    <Wrapper restricted={!canUse}>
      <MuiButton
        {...props}
        sx={sx}
        {...(!canUse && (to || props.onClick)
          ? {
              onClick: () => {},
              to: '/upgrade/',
              state: { feature }
            }
          : {
              to
            })}
      >
        {!canUse && <GrUpgrade className="button__restricted-icon" />}
        {children}
      </MuiButton>
    </Wrapper>
  )
}

const Wrapper: FC<{
  restricted: boolean
  children: ReactElement<any, any>
}> = ({ restricted = true, children }) => {
  return !restricted ? (
    <>{children}</>
  ) : (
    <Tooltip title="Upgrade required">{children}</Tooltip>
  )
}
