import { Surface } from '@components'
import { Box, Container, Grid } from '@mui/material'
import { GenericPageProps } from '@templates/Generic/Page'
import React, { ReactElement, ReactNode } from 'react'

export interface NoticeProps {
  maxWidth?: GenericPageProps['maxWidth']
  icon: ReactNode
  message: ReactNode
  action: ReactNode
}

export default function index({
  maxWidth,
  icon,
  message,
  action
}: NoticeProps): ReactElement {
  return (
    <Surface
      sx={{
        borderWidth: '0 0 1px 0',
        borderRadius: 0,
        mb: 1
      }}
      style={{}}
      elevation={0}
    >
      <Box py={3}>
        <Container maxWidth={maxWidth}>
          <Grid
            container
            alignItems="center"
            alignContent="space-between"
            spacing={3}
          >
            <Grid item xs style={{ flexGrow: 0 }}>
              {icon}
            </Grid>
            <Grid item xs>
              {message}
            </Grid>
            <Grid item xs style={{ flexGrow: 0 }}>
              {action}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Surface>
  )
}
