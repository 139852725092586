import { Box } from '@mui/material'
import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  const hidden = value !== index

  return (
    <Box
      display={!hidden ? 'flex' : 'none'}
      alignItems="stretch"
      height={'100%'}
      flexGrow={1}
      hidden={hidden}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3} flexGrow={1} width={'100%'}>
          {children}
        </Box>
      )}
    </Box>
  )
}
