import { useForm } from '@mantine/hooks'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps
} from '@mui/material'
import React, { ReactElement } from 'react'

export type TSelect = SelectProps & {
  id: string
  label: string
  options?: {
    value: string | number | boolean
    label: string | number
    disabled?: boolean
  }[]
  form: ReturnType<typeof useForm>
  helperText?: string
  placeholder?: string
  required?: boolean
  isNumber?: boolean
}

function Select({
  id,
  label,
  options = [],
  form,
  required = false,
  isNumber = false,
  helperText,
  ...props
}: TSelect): ReactElement {
  const handleChange = (event: SelectChangeEvent) => {
    let { value } = event.target

    if (value === 'true' || value === 'false') {
      form.setFieldValue(id, value === 'true' ? true : false)
    } else if (isNumber && typeof value === 'string') {
      form.setFieldValue(id, parseInt(value, 10))
    } else {
      form.setFieldValue(id, value)
    }
  }

  return (
    <FormControl
      error={form.errors[id]}
      required={required}
      variant="outlined"
      sx={{
        minWidth: 120,
        width: '100%'
      }}
    >
      <InputLabel id={`${id}-label`} shrink={true}>
        {label}
      </InputLabel>
      <MuiSelect
        MenuProps={{
          elevation: 2
        }}
        labelId={`${id}-label`}
        label={label}
        id={id}
        fullWidth
        value={form.values[id]}
        onChange={handleChange}
        onBlur={() => form.validateField(id)}
        {...props}
      >
        {props.placeholder && (
          <MenuItem value="" disabled>
            {props.placeholder}
          </MenuItem>
        )}
        {options.map(({ label, value, disabled = false }, index) => (
          <MenuItem
            disabled={
              disabled || value === undefined || value === null || value === ''
            }
            key={`select-${value}-${index}`}
            value={`${value}`}
          >
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
