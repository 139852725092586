import {
  DataGridProProps,
  getGridStringOperators,
  GridColTypeDef,
  GridFilterModel,
  GridLinkOperator,
  GridRowProps
} from '@mui/x-data-grid-pro'
import { GenericTablePageProps } from '@templates/Generic/Table'
import uniqid from 'uniqid'
import { hostPresets } from './hosts'
import {
  chipRenderer,
  statusRenderer,
  tagRenderer,
  tooltipRenderer,
  upgradeStringFilter
} from './tables'

export const runIdColumnType: GridColTypeDef = {
  extendType: 'number',
  filterOperators: getGridStringOperators().filter(
    (operator) => operator.value === 'equals' || operator.value === 'startsWith'
  )
}

export const canRequest = (filter?: GridFilterModel): boolean => {
  if (!filter) return false

  if (filter.items.length === 0) return true

  if (
    filter.items.find(
      ({ value, operatorValue }) =>
        !operatorValue ||
        ((value === undefined || value === null) &&
          !['isEmpty', 'isNotEmpty'].includes(operatorValue))
    )
  )
    return false

  return true
}

export const resultsColumns: DataGridProProps['columns'] =
  upgradeStringFilter([
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      hide: true,
      type: 'enhancedString'
    },
    {
      field: 'status_icon',
      headerName: '',
      width: 50,
      renderCell: statusRenderer,
      align: 'center',
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      valueGetter: (params: GridRowProps) => params.row['data.status']
    },
    {
      field: 'header.hostname',
      headerName: 'Target Address',
      width: 150,
      type: 'enhancedString'
    },
    {
      field: 'header.node_name',
      headerName: 'Hostname',
      width: 150,
      type: 'enhancedString',
      renderCell: tooltipRenderer
    },
    {
      field: 'header.host_id',
      headerName: 'Host ID',
      width: 150,
      type: 'enhancedString',
      hide: true
    },
    {
      field: 'data.status',
      headerName: 'Result',
      width: 130,
      hide: true,
      type: 'singleSelect',
      valueOptions: ['pass', 'error', 'alert']
    },
    {
      field: 'data.tags',
      headerName: 'Sandfly Tags',
      width: 250,
      sortable: false,
      renderCell: tagRenderer,
      type: 'enhancedString'
    },
    {
      field: 'data.name',
      headerName: 'Sandfly',
      width: 250,
      renderCell: tooltipRenderer
    },
    {
      field: 'header.ip_addr',
      headerName: 'IP',
      type: 'enhancedString',

      width: 180,
      hide: true
    },
    {
      field: 'header.queue_name',
      headerName: 'Queue',
      type: 'enhancedString',

      width: 180,
      hide: true
    },
    {
      field: 'header.run_id',
      headerName: 'Run ID',

      width: 180,
      type: 'runId',
      hide: true
    },
    {
      field: 'data.key_data',
      headerName: 'Key Forensic',
      width: 120
    },

    {
      field: 'data.severity',
      headerName: 'Severity',

      width: 100,
      type: 'number'
    },
    {
      field: 'data.end_time',
      headerName: 'Time',
      width: 170,
      type: 'dateTime'
    },
    {
      field: 'header.tags',
      headerName: 'Host Tags',
      type: 'enhancedString',

      width: 250,
      sortable: false,
      renderCell: chipRenderer
    },
    {
      field: 'data.exec_seconds',
      headerName: 'Execution (sec)',
      width: 130,
      hide: true,
      type: 'number'
    },
    {
      field: 'data.type',
      headerName: 'Type',
      type: 'enhancedString',
      width: 180,
      hide: true
    }
  ])

export const resultsHostPresets: GenericTablePageProps['gridOptions']['presets'] =
  [
    {
      label: 'Hosts with Alerts',
      filter: {
        items: [
          {
            columnField: 'results_alert',
            operatorValue: '>',
            value: 0,
            id: 1
          }
        ],
        linkOperator: 'and' as GridLinkOperator
      }
    },
    {
      label: 'Hosts with Errors',
      filter: {
        items: [
          {
            columnField: 'results_error',
            operatorValue: '>',
            value: 0,
            id: 1
          }
        ],
        linkOperator: 'and' as GridLinkOperator
      }
    },
    {
      label: 'Hosts with Passes',
      filter: {
        items: [
          {
            columnField: 'results_pass',
            operatorValue: '>',
            value: 0,
            id: 1
          }
        ],
        linkOperator: 'and' as GridLinkOperator
      }
    },
    ...hostPresets
  ]

export const resultsPresets: GenericTablePageProps['gridOptions']['presets'] = [
  {
    label: 'Show All',
    filter: {
      items: [],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Pass Only',
    filter: {
      items: [
        {
          columnField: 'data.status',
          operatorValue: 'is',
          value: 'pass',
          id: uniqid.time()
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Alert Only',
    filter: {
      items: [
        {
          columnField: 'data.status',
          operatorValue: 'is',
          value: 'alert',
          id: uniqid.time()
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Error Only',
    filter: {
      items: [
        {
          columnField: 'data.status',
          operatorValue: 'is',
          value: 'error',
          id: uniqid.time()
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  }
]
