import React from 'react'
import {
  FaUbuntu,
  FaCentos,
  FaFedora,
  FaRedhat,
  FaLinux,
  FaAws,
  FaSuse
} from 'react-icons/fa'
import { DiDebian } from 'react-icons/di'
import { IconBaseProps } from 'react-icons'
import Oracle from '@svgs/oracle-logo.svg'

export const getLinuxDistIcon = (
  name: string,
  props: IconBaseProps = { fontSize: 24, display: 'block' }
) => {
  switch (name) {
    case 'Ubuntu':
      return <FaUbuntu {...props} />

    case 'CentOS':
      return <FaCentos {...props} />

    case 'Fedora':
      return <FaFedora {...props} />

    case 'Debian':
      return <DiDebian {...props} />

    case 'Red':
      return <FaRedhat {...props} />

    case 'Amazon':
      return <FaAws {...props} />

    case 'SUSE':
      return <FaSuse {...props} />

    case 'Oracle':
      return <Oracle {...props} height="1em" width="1em" />

    default:
      return <FaLinux {...props} />
  }
}
