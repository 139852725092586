import { TextField as MUITextField, TextFieldProps } from '@mui/material'
import React, { ReactElement, useMemo } from 'react'

const defaults: TextFieldProps = {
  InputLabelProps: {
    shrink: true
  },
  fullWidth: true,
  variant: 'outlined'
}

export type TTextField = TextFieldProps & {
  id: string
  form?: any
}

export default function TextField({
  form,
  ...props
}: TTextField): ReactElement {
  if (!props.label && props.id) {
    defaults.label = useMemo(
      () => props.id.charAt(0).toUpperCase() + props.id.slice(1),
      [props.id]
    )
  }

  const controlledProps = form
    ? {
        value: form.values[props.id],
        error: form.errors[props.id],
        onChange: (
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          const { value } = event.currentTarget
          const isNumber = props.type && props.type === 'number' && value
          form.setFieldValue(props.id, isNumber ? parseInt(value, 10) : value)
        },
        onBlur: () => form.validateField(props.id)
      }
    : {}

  const config = {
    ...defaults,
    ...controlledProps
  }

  return <MUITextField {...config} {...props} />
}
