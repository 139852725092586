import { MITRE_TACTIC_DICTIONARY, TTactic } from '.'
import { MITRE_TECHNIQUE_DICTIONARY } from './techniques'

const allTerms = {
  ...MITRE_TACTIC_DICTIONARY,
  ...MITRE_TECHNIQUE_DICTIONARY
}

export const getMitreDefById = (id: keyof typeof MITRE_TACTIC_DICTIONARY) => {
  return allTerms?.[id] || false
}

export const getMitreDefByName = (name: TTactic['name']) => {
  return (
    Object.values(allTerms).find(
      (def) => def.name.toLowerCase() === name.toLowerCase()
    ) || false
  )
}
