import { Button } from '@components'
import { Badge } from '@mui/material'
import QueueContext from '@utils/contexts/QueueContext'
import React from 'react'

const AppBarQueueButton: React.FC = ({ children }) => {
  const { count } = React.useContext(QueueContext)

  return (
    <Badge badgeContent={count} max={9} color="primary">
      <Button
        data-cy="taskQueueButton"
        color="inherit"
        variant="outlined"
        to={'/scan/queue/'}
      >
        {children}
      </Button>
    </Badge>
  )
}

export default AppBarQueueButton
