import React, { ReactElement, useEffect, useState } from 'react'
import moment from 'moment'
import { Box, Chip } from '@mui/material'

interface Props {
  mode?: 'Local' | 'UTC'
}

const isBrowser = typeof window !== `undefined`

function Clock({ mode = 'UTC' }: Props): ReactElement {
  const [clockMode, setClockMode] = useState(mode)
  const [time, setTime] = useState<any>(moment())

  useEffect(() => {
    // setTime(() => moment())

    if (isBrowser) {
      const intervalID = setInterval(() => setTime(moment()), 1000)
      return () => clearInterval(intervalID)
    }
  }, [])

  const timeString = () =>
    clockMode === 'UTC' ? time.utc().format() : time.format()

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        position: 'relative',
        px: 2,
        py: 1,
        '&::before': {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'block',
          opacity: 0.1,
          borderRadius: (theme) => theme.spacing(2),
          backgroundColor: 'common.white',
          content: '""'
        },

        '& time': {
          mr: 1,
          fontFamily: 'monospace',
          fontWeight: 700
        }
      }}
    >
      <Box sx={{ minWidth: '176px' }}>
        <time> {timeString()} </time>
      </Box>
      <Chip
        clickable
        size="small"
        label={clockMode}
        onClick={() => setClockMode(clockMode === 'UTC' ? 'Local' : 'UTC')}
      />
    </Box>
  )
}

export default Clock
