import React, { useEffect, useState } from 'react'
import { Box, Theme, useTheme } from '@mui/material'
import {
  DataGridPro,
  GridCellEditCommitParams,
  GridEditRowsModel,
  GridFilterModel,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  DataGridProProps
} from '@mui/x-data-grid-pro'
import DataGridToolbar, { TBulkActions, TPresets } from './DataGridToolbar'
import DataGridFooter from './DataGridFooter'
import {
  blankFilter,
  enhancedStringColumnType,
  filterModelDecorator
} from '@utils/tables'
import Surface from '@components/Surface'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import {
  getBackgroundColor,
  getHoverSurfaceColor,
  getSurfaceColor
} from '@utils/theme'
import { runIdColumnType } from '@utils/results'

export interface IDataGridProps {
  config: DataGridProProps & {
    elevation?: number
    bulkActions?: TBulkActions
    presets?: TPresets
    editCellHandler?: (
      params: GridCellEditCommitParams,
      editRowsModel: GridEditRowsModel,
      setEditRowsModel: React.Dispatch<React.SetStateAction<GridEditRowsModel>>
    ) => void
    makeRequest?: () => void
    height?: React.CSSProperties['height']
    total?: number
    maxResults?: number
    setFilter?: React.Dispatch<React.SetStateAction<GridFilterModel>>
    setSort?: React.Dispatch<React.SetStateAction<GridSortModel>>
    variant?: 'default' | 'error'
    selectVisibleOnly?: boolean
    historySupport?: boolean
    initialSort?: GridSortModel
    initialFilter?: GridFilterModel
  }
}

interface LocationState {
  sort?: GridSortModel
  filter?: GridFilterModel
}

const DataGrid = ({
  config: {
    elevation = 1,
    maxResults = 100000,
    selectVisibleOnly = true,
    historySupport = false,
    bulkActions,
    makeRequest,
    presets,
    ...config
  }
}: IDataGridProps) => {
  // const classes = useStyles(config)
  const { palette } = useTheme()
  const red = getBackgroundColor(palette.error.main, palette.mode)
  const bg = palette.background.paper

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>(
    {}
  )
  const location = useLocation()
  const state = (location.state as LocationState) || ({} as LocationState)

  const {
    sort: historySort = config.initialSort,
    filter: historyFilter = config.filterModel
  } = state

  const [filter, setFilter] = useState<GridFilterModel>(
    filterModelDecorator(historyFilter || config.initialFilter || blankFilter)
  )

  const [sort, setSort] = useState<GridSortModel>(historySort || [])

  const handleFilterChange = (filterModel: GridFilterModel) => {
    const newFilterModel = filterModelDecorator(filterModel)

    if (config.setFilter) {
      config.setFilter(newFilterModel)
    } else {
      setFilter(newFilterModel)
    }
  }

  const handleSortChange = (sortModel: GridSortModel) => {
    if (config.setSort) {
      config.setSort(sortModel)
    } else {
      setSort(sortModel)
    }
  }

  const handleEditCellChange = React.useCallback(
    (params: GridCellEditCommitParams) => {
      config.editCellHandler &&
        config.editCellHandler(params, editRowsModel, setEditRowsModel)
    },
    [editRowsModel]
  )

  const { height } = config
  const total = config.total || config.rows.length

  useEffect(() => {
    if (historySupport) {
      navigate(`${location.pathname}`, {
        state: { sort, filter },
        replace: true
      })
    }
  }, [sort, filter])

  return (
    <Box
      display="flex"
      height={height || '100%'}
      flexGrow={1}
      sx={{
        '& .MuiDataGrid-row': {
          cursor: config.onRowClick ? 'pointer' : 'inherit'
        },
        '& .DataGrid__row--whitelisted-true': {
          backgroundImage: `linear-gradient(45deg, ${red} 25%, ${bg} 25%, ${bg} 50%, ${red} 50%, ${red} 75%, ${bg} 75%, ${bg} 100%);`,
          backgroundSize: '60px 60px'
        },
        '&& .DataGrid__row--active-false': {
          backgroundColor: ({ palette }) =>
            getSurfaceColor(palette.warning.main, palette.mode),
          '&:hover': {
            backgroundColor: ({ palette }) =>
              getHoverSurfaceColor(palette.warning.main, palette.mode)
          }
        },
        '& .DataGrid__row--has-passes': {
          '& .DataGrid__cell--status-style': {
            color: 'success.main',
            fontWeight: 600
          }
        },

        '& .DataGrid__row--has-errors': {
          '& .DataGrid__cell--status-style': {
            color: 'error.main',
            fontWeight: 600
          }
        },

        '& .DataGrid__row--no-results': {
          cursor: 'default'
        },

        '& .DataGrid__row--is-stale': {
          '& .DataGrid__cell--last-seen': {
            color: 'warning.main'
          }
        },

        '& .DataGrid__row--is-unknown': {
          color: 'text.disabled',
          '& .DataGrid__cell--last-seen': {
            color: 'text.disabled'
          }
        },

        '& .DataGrid__row--has-alerts': {
          '& .DataGrid__cell--status-style': {
            color: 'error.main',
            fontWeight: 600
          }
        },

        '& .DataGrid__cell--show-button-on-hover': {
          button: {
            visibility: 'hidden'
          }
        },

        '& :hover > .DataGrid__cell--show-button-on-hover': {
          button: {
            visibility: 'visible'
          }
        },

        '& .DataGrid__upgrade-icon': {
          color: 'secondary.main',
          marginRight: '0.25rem',
          marginTop: '0.375rem',
          verticalAlign: 'middle',
          lineHeight: 1.6
        },

        '& .DataGrid__upgrade-text': {
          // filter: 'blur(4px)'
        },
        '& .MuiDataGrid-row.DataGrid__row--masked-true': {
          position: 'relative',
          // cursor: 'inherit',
          fontStyle: 'italic',
          filter: 'blur(4px)'
        },
        '&& .DataGrid__row--disabled-false': {
          color: 'text.disabled',

          '&:hover': {
            color: 'text.disabled'
          }
        },
        '&& .MuiDataGrid-columnHeader:focus, && .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGridPanel-paper': {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: ({ palette }) => palette.divider,
          overflow: 'hidden',
          height: '100%'
        }
      }}
      data-cy="dataGrid"
    >
      <Surface
        elevation={elevation}
        sx={{
          flexGrow: 1,
          border: 0,
          height: 'auto',
          '& .MuiDataGrid-root': {
            borderColor: (theme: Theme) =>
              config.variant === 'error'
                ? theme.palette.error.main
                : theme.palette.divider
          }
        }}
      >
        <DataGridPro
          loading={true}
          onCellEditCommit={handleEditCellChange}
          checkboxSelection={true}
          disableSelectionOnClick
          disableColumnMenu
          columnTypes={{
            enhancedString: enhancedStringColumnType,
            runId: runIdColumnType
          }}
          components={{
            Toolbar: DataGridToolbar,
            Footer: DataGridFooter
          }}
          componentsProps={{
            toolbar: {
              selectionModel,
              bulkActions,
              makeRequest,
              presets,
              setFilter: config.setFilter || setFilter,
              setSort: config.setSort || setSort,
              setSelection: setSelectionModel
            },
            footer: {
              total,
              maxResults,
              loading: config.loading
            }
          }}
          onSelectionModelChange={(selectionModel: GridSelectionModel) => {
            setSelectionModel(selectionModel)
          }}
          selectionModel={selectionModel}
          filterModel={filter}
          onFilterModelChange={handleFilterChange}
          sortModel={sort}
          onSortModelChange={handleSortChange}
          {...config}
        />
      </Surface>
    </Box>
  )
}

export default DataGrid
