import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { Skeleton } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {}

export function PlaceholderListItem({}: Props): ReactElement {
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Skeleton variant="circular" height={40} width={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton width="60%" />}
        secondary={<Skeleton width="50%" />}
      />
    </ListItem>
  )
}
