import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
  Divider,
  Grid,
  Stack
} from '@mui/material'
import { Skeleton } from '@mui/material'
import React, { ReactChild, ReactElement } from 'react'

interface Props {
  heading?: string
  dataPoints: ([string, any] | [string, any, ReactElement])[]
  actions?: ReactChild
  headingButton?: ReactChild
  loading?: boolean
  variant?: 'table' | 'label-over'
}

function KeyValueTable({
  heading,
  dataPoints,
  actions,
  headingButton,
  variant = 'table',
  loading = false
}: Props): ReactElement {
  return (
    <div>
      {heading && (
        <>
          <Box p={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">{heading}</Typography>
              </Grid>
              {headingButton && <Grid item>{headingButton}</Grid>}
            </Grid>
          </Box>
          <Divider />
        </>
      )}
      {variant === 'table' && (
        <Table aria-label={heading} sx={{ width: '100%' }}>
          <TableBody>
            {dataPoints &&
              dataPoints.map((row) => (
                <TableRow key={row[0]}>
                  <TableCell
                    component="th"
                    scope="row"
                    variant="head"
                    sx={{ color: 'text.secondary' }}
                  >
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">
                    {!loading ? row[1] : <Skeleton />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}

      {variant === 'label-over' &&
        dataPoints &&
        dataPoints.map((row, index) => (
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={row[0]}
            >
              <Box p={2}>
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  {row[0]}
                </Typography>
                <Typography variant="body2" component="div">
                  {!loading ? row[1] : <Skeleton />}
                </Typography>
              </Box>
              {row[2] && (
                <Box p={2} alignSelf="center">
                  {row[2]}
                </Box>
              )}
            </Stack>
            {index < dataPoints.length - 1 && <Divider />}
          </div>
        ))}

      {actions && (
        <Box p={1} textAlign="right">
          {actions}
        </Box>
      )}
    </div>
  )
}

export default KeyValueTable
