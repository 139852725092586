import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Stack,
  ThemeProvider
} from '@mui/material'
import { Close, MoreHoriz } from '@mui/icons-material'
import React, { ReactElement, useContext } from 'react'
import config from './config'
import { useLocation } from '@reach/router'
import SidebarItem from './SidebarItem'
import SidebarParentItem from './SidebarParentItem'
import SidebarContext from '@utils/contexts/SidebarContext'
import { dark } from '@utils/theme'

export default function Sidebar({}): ReactElement {
  const items = config.mainItems || []
  const secondaryItems = config.secondaryItems || []
  const { isSidebarOpen, setSidebar } = useContext(SidebarContext)
  const { pathname } = useLocation()
  return (
    <ThemeProvider theme={dark}>
      <Paper
        sx={{
          height: '100%',
          pt: 8,
          color: 'text.primary',
          background: ({ palette }) => palette.background.default,
          '& a': {
            color: 'text.primary'
          }
        }}
      >
        <Stack justifyContent="space-between" sx={{ height: '100%' }}>
          <List dense>
            <ListItem
              dense={false}
              style={{ minHeight: 28 }}
              data-cy="sidebarToggle"
            >
              <ListItemSecondaryAction aria-label="expand menu">
                <IconButton
                  onClick={() => setSidebar(!isSidebarOpen)}
                  edge="end"
                  size="small"
                  sx={(theme) =>
                    !isSidebarOpen
                      ? {
                          transition: theme.transitions.create('transfrom', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen
                          }),
                          transform: 'translateX(-0.5rem)'
                        }
                      : {}
                  }
                >
                  {isSidebarOpen ? (
                    <Close fontSize="small" />
                  ) : (
                    <MoreHoriz fontSize="small" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {items.map((item, index) => {
              const current = pathname.startsWith(item.path)
              return item.children ? (
                <SidebarParentItem
                  key={item.title + index}
                  item={item}
                  current={current}
                  main={true}
                />
              ) : (
                <SidebarItem key={item.title + index} item={item} main={true} />
              )
            })}
          </List>
          <List
            dense
            sx={{
              pb: 1,
              '& hr ': {
                mb: 2
              }
            }}
          >
            <Divider />
            {secondaryItems.map((item, index) => (
              <SidebarItem key={item.title + index} item={item} />
            ))}
          </List>
        </Stack>
      </Paper>
    </ThemeProvider>
  )
}
