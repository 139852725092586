import React from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useForm } from '@mantine/hooks'

interface IRadioButtonGroup {
  name: string
  label: string
  options: {
    value: string
    label: string
  }[]
  form: ReturnType<typeof useForm>
}

export default function RadioButtonGroup({
  name,
  label,
  options,
  form
}: IRadioButtonGroup) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(name, event?.target.value || '')
  }

  return (
    <Box>
      <FormControl component="fieldset">
        <FormLabel sx={{ fontSize: '0.875rem' }} component="legend">
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={name}
          name={name}
          onChange={handleChange}
          value={form.values[name]}
        >
          {options.map((option) => (
            <FormControlLabel
              key={`${name}-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
