import { SandflyType, SANDFLY_TYPES } from './api'
import { underscoreToTitleCase } from './misc'
import { getMitreDefById, getMitreDefByName } from './mitre'

export function transformTagsToKeywords(tagsArray: string[]) {
  const keywords: string[] = []

  tagsArray.forEach((tag) => {
    const newTag = tag.replace('attack.', '').replace('id.', '')

    keywords.push(...newTag.split('.'))
  })

  return [...new Set(keywords)]
}

type TClassifiedTag = {
  label: string
  id: string
  tooltip: string | boolean
  type: 'mitre_technique' | 'mitre_tactic' | 'sandfly_type' | 'custom'
  reference: string | boolean
}

type TClassifiedTags = {
  sandflyClass: TClassifiedTag | null
  mitreTechnique: TClassifiedTag[]
  mitreTactic: TClassifiedTag[]
  misc: TClassifiedTag[]
}

export function classifyTags(tagsArray: string[]) {
  const classifiedTags: TClassifiedTags = {
    sandflyClass: null,
    mitreTechnique: [],
    mitreTactic: [],
    misc: []
  }

  tagsArray.forEach((tag) => {
    // Pick out sandfly type
    if (SANDFLY_TYPES.indexOf(tag as SandflyType) > -1) {
      classifiedTags.sandflyClass = {
        id: tag,
        label: underscoreToTitleCase(tag),
        type: 'sandfly_type',
        tooltip: false,
        reference: false
      }

      return
    }

    // Pick out Mitre Ids
    if (tag.indexOf('attack.id') > -1) {
      const id = tag.replace('attack.id.', '')
      const def = getMitreDefById(id)

      classifiedTags.mitreTechnique.push({
        id,
        label: id,
        type: 'mitre_technique',
        reference: def ? def.reference : false,
        tooltip: def ? def.description : false
      })

      return
    }

    // Pick out Mitre Tactic
    if (tag.indexOf('attack.tactic') > -1) {
      const tactic = underscoreToTitleCase(tag.replace('attack.tactic.', ''))
      const def = getMitreDefByName(tactic)
      classifiedTags.mitreTactic.push({
        id: tactic,
        label: tactic,
        type: 'mitre_tactic',
        reference: def ? def.reference : false,
        tooltip: def ? def.description : false
      })

      return
    }

    // everything else
    classifiedTags.misc.push({
      id: tag,
      label: underscoreToTitleCase(tag),
      type: 'custom',
      tooltip: false,
      reference: false
    })
  })

  return classifiedTags
}
