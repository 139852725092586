import AuthContext from '@utils/contexts/AuthContext'
import { License } from '@utils/api'
import moment from 'moment'
import { useContext } from 'react'
import LicenseContext from '@utils/contexts/LicenseContext'

/**
 * Provides user and license data
 */
const useLicense = () => {
  const { loading, license, refreshData, error } = useContext(LicenseContext)

  const isUnlicensed =
    !loading && ((license && license.license_level === -1) || !license)

  const isTrial = license && license.license_level === 0

  const refreshLimits = (): void => refreshData()

  const getLimit = (feature: keyof License['usage']): number => {
    if (!license) return 0

    return license.limits[feature] || 0
  }

  const hasExceededLimit = (feature: keyof License['usage']): boolean => {
    if (!license || !license.usage) return false

    return license.usage[feature] > license.limits[feature]
  }

  const getUsage = (feature: keyof License['usage']): number => {
    if (!license) return 0

    return license.limits[feature] || 0
  }

  const isAfter = (date: string): boolean => moment().isAfter(date)

  return {
    error,
    refreshLimits,
    license,
    isTrial,
    isUnlicensed,
    loading,
    getLimit,
    getUsage,
    hasExceededLimit,
    get isExpiring() {
      return !license ? false : isAfter(license.date.warning)
    },
    get hasExpired() {
      return !license ? false : isAfter(license.date.expiry)
    }
  }
}

export default useLicense
