import { Typography } from '@mui/material'
import { Variant } from '@mui/styles/createTypography'
import React, { ReactElement } from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => {
  const tags: Variant[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  const nestedRules: { [key: string]: { [key: string]: any } } = {}
  const { typography } = theme
  tags.forEach((tag) => {
    nestedRules[`& ${tag}`] = { ...typography[tag] }
  })
  return {
    root: {
      ...nestedRules,

      '& pre': {
        maxWidth: '100%',
        overflowX: 'auto',
        minWidth: 0,
        whiteSpace: 'break-spaces',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(4),
        borderRadius: 5
      },

      '& pre.language-json': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        fontSize: '0.875rem'
      },

      '& .property': {
        color: theme.palette.secondary.main
      },

      '& .string': {
        color: theme.palette.text.secondary
      },

      '& .number': {
        color: theme.palette.warning.dark
      },

      '& .boolean': {
        color: theme.palette.warning.dark
      },

      '& dt': {
        ...theme.typography['subtitle1'],
        fontFamily: 'monospace, monospace'
      },
      '& dd': {
        ...theme.typography['body1'],
        marginLeft: 0,
        marginBottom: theme.spacing(1)
      }
    }
  }
})

interface Props {
  html: string
}

function Content({ html }: Props): ReactElement {
  const classes = useStyles()

  return (
    <div>
      <Typography
        className={classes.root}
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: html }}
      ></Typography>
    </div>
  )
}

export default Content
