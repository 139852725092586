import { Divider, Grid, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  total: number
  maxResults: number
  loading: boolean
}

export default function DataGridFooter({
  total,
  maxResults,
  loading
}: Props): ReactElement {
  const exceededMax = total > maxResults

  return (
    <div>
      <Divider />
      <Grid container justifyContent="flex-end" spacing={2} sx={{ p: 1 }}>
        {exceededMax ? (
          <Grid item>
            <Typography
              color="error"
              variant="caption"
            >{`Max Results: ${maxResults}`}</Typography>
          </Grid>
        ) : (
          <Grid item data-cy="count">
            <Typography variant="caption">
              {loading ? (
                <Skeleton height={21} width={200} />
              ) : (
                `Total Result Count: ${total}`
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
