import React, { FC, ReactChild } from 'react'
import { Alert, AlertTitle, Modal, Stack, darken } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'

type Props = {
  title?: string
  message?: ReactChild
  onProceed?: () => void
  [key: string]: any
}

const WithCaution: FC<Props> = ({
  title = 'Warning',
  message = 'Are you sure you wish to proceed?',
  onProceed,
  children,
  ...props
}) => {
  const [open, setOpen] = React.useState(false)
  let elements = React.Children.toArray(children)

  if (elements.length !== 1) {
    console.error(
      'WithCaution should only be used with a single element with a onClick handler'
    )
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const originalClickHandlers: (() => void)[] = []
  const newElements: any[] = []

  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) return

    const { onClick } = element.props

    if (onClick) originalClickHandlers.push(onClick)

    newElements.push(
      React.cloneElement(element, {
        onClick: (event: React.MouseEvent<any>) => {
          event.preventDefault()
          event.stopPropagation()
          handleOpen()
        },
        ...props
      })
    )
  })

  const dispatchOriginalOnClicks = () => {
    originalClickHandlers.forEach((method) => method())
  }
  return (
    <React.Fragment key="caution-wrapper">
      <React.Fragment key="wrapped-content">{newElements[0]}</React.Fragment>

      <Modal
        key="model-content"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Alert
          variant="filled"
          sx={{
            m: 'auto',
            maxWidth: 500
          }}
          severity="error"
          elevation={4}
          action={
            <Stack direction="row" spacing={1}>
              <Button
                data-cy="cautionProceed"
                sx={{
                  color: 'error.main',
                  bgcolor: 'error.contrastText',
                  '&:hover': {
                    bgcolor: ({ palette }) =>
                      darken(palette.error.contrastText, 0.1)
                  }
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  dispatchOriginalOnClicks()
                  handleClose()
                }}
              >
                Proceed
              </Button>

              <Button
                data-cy="cautionCancel"
                sx={{
                  color: 'common.white',
                  borderColor: 'currentColor',
                  '&:hover': {
                    borderColor: 'currentColor'
                  }
                }}
                variant="outlined"
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          }
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Modal>
    </React.Fragment>
  )
}

export default WithCaution
