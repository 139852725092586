// import ThemeContext from '@contexts/themeContext'
import { Collapse, List } from '@mui/material'
import SidebarContext from '@utils/contexts/SidebarContext'
import React, { useContext } from 'react'
import SidebarItem, { SidebarItemProps } from './SidebarItem'

const SidebarParentItem = ({
  item,
  className,
  current = false,
  main = true
}: SidebarItemProps) => {
  const { isSidebarOpen } = useContext(SidebarContext)
  const { children } = item
  const [open, setOpen] = React.useState(!!current)
  const parentConfig = {
    open,
    setOpen,
    main
  }

  return (
    <>
      <SidebarItem item={item} {...parentConfig} />
      {children && (
        <Collapse in={open && isSidebarOpen} timeout="auto" unmountOnExit>
          <List dense component="div" disablePadding>
            {children.map((item, index) => (
              <SidebarItem key={item.title + index} item={item} nested={true} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default SidebarParentItem
