type TTechnique = {
  id: string
  name: string
  description: string
  reference: string
}

export const MITRE_TECHNIQUE_SITE = 'https://attack.mitre.org/techniques'

export const MITRE_TECHNIQUE_DICTIONARY: { [key: string]: TTechnique } = {
  T1001: {
    id: 'T1001',
    name: 'Data Obfuscation',
    description: 'Data Obfuscation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1001`
  },
  'T1001.001': {
    id: 'T1001.001',
    name: 'Data Obfuscation: Junk Data',
    description: 'Data Obfuscation: Junk Data',
    reference: `${MITRE_TECHNIQUE_SITE}/T1001/001`
  },
  'T1001.002': {
    id: 'T1001.002',
    name: 'Data Obfuscation: Steganography',
    description: 'Data Obfuscation: Steganography',
    reference: `${MITRE_TECHNIQUE_SITE}/T1001/002`
  },
  'T1001.003': {
    id: 'T1001.003',
    name: 'Data Obfuscation: Protocol Impersonation',
    description: 'Data Obfuscation: Protocol Impersonation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1001/003`
  },
  T1003: {
    id: 'T1003',
    name: 'OS Credential Dumping',
    description: 'OS Credential Dumping',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003`
  },
  'T1003.001': {
    id: 'T1003.001',
    name: 'OS Credential Dumping: LSASS Memory',
    description: 'OS Credential Dumping: LSASS Memory',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/001`
  },
  'T1003.002': {
    id: 'T1003.002',
    name: 'OS Credential Dumping: Security Account Manager',
    description: 'OS Credential Dumping: Security Account Manager',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/002`
  },
  'T1003.003': {
    id: 'T1003.003',
    name: 'OS Credential Dumping: NTDS',
    description: 'OS Credential Dumping: NTDS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/003`
  },
  'T1003.004': {
    id: 'T1003.004',
    name: 'OS Credential Dumping: LSA Secrets',
    description: 'OS Credential Dumping: LSA Secrets',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/004`
  },
  'T1003.005': {
    id: 'T1003.005',
    name: 'OS Credential Dumping: Cached Domain Credentials',
    description: 'OS Credential Dumping: Cached Domain Credentials',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/005`
  },
  'T1003.006': {
    id: 'T1003.006',
    name: 'OS Credential Dumping: DCSync',
    description: 'OS Credential Dumping: DCSync',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/006`
  },
  'T1003.007': {
    id: 'T1003.007',
    name: 'OS Credential Dumping: Proc Filesystem',
    description: 'OS Credential Dumping: Proc Filesystem',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/007`
  },
  'T1003.008': {
    id: 'T1003.008',
    name: 'OS Credential Dumping: /etc/passwd and /etc/shadow',
    description: 'OS Credential Dumping: /etc/passwd and /etc/shadow',
    reference: `${MITRE_TECHNIQUE_SITE}/T1003/008`
  },
  T1005: {
    id: 'T1005',
    name: 'Data from Local System',
    description: 'Data from Local System',
    reference: `${MITRE_TECHNIQUE_SITE}/T1005`
  },
  T1006: {
    id: 'T1006',
    name: 'Direct Volume Access',
    description: 'Direct Volume Access',
    reference: `${MITRE_TECHNIQUE_SITE}/T1006`
  },
  T1007: {
    id: 'T1007',
    name: 'System Service Discovery',
    description: 'System Service Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1007`
  },
  T1008: {
    id: 'T1008',
    name: 'Fallback Channels',
    description: 'Fallback Channels',
    reference: `${MITRE_TECHNIQUE_SITE}/T1008`
  },
  T1010: {
    id: 'T1010',
    name: 'Application Window Discovery',
    description: 'Application Window Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1010`
  },
  T1011: {
    id: 'T1011',
    name: 'Exfiltration Over Other Network Medium',
    description: 'Exfiltration Over Other Network Medium',
    reference: `${MITRE_TECHNIQUE_SITE}/T1011`
  },
  'T1011.001': {
    id: 'T1011.001',
    name: 'Exfiltration Over Other Network Medium: Exfiltration Over Bluetooth',
    description:
      'Exfiltration Over Other Network Medium: Exfiltration Over Bluetooth',
    reference: `${MITRE_TECHNIQUE_SITE}/T1011/001`
  },
  T1012: {
    id: 'T1012',
    name: 'Query Registry',
    description: 'Query Registry',
    reference: `${MITRE_TECHNIQUE_SITE}/T1012`
  },
  T1014: {
    id: 'T1014',
    name: 'Rootkit',
    description: 'Rootkit',
    reference: `${MITRE_TECHNIQUE_SITE}/T1014`
  },
  T1016: {
    id: 'T1016',
    name: 'System Network Configuration Discovery',
    description: 'System Network Configuration Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1016`
  },
  'T1016.001': {
    id: 'T1016.001',
    name: 'System Network Configuration Discovery: Internet Connection Discovery',
    description:
      'System Network Configuration Discovery: Internet Connection Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1016/001`
  },
  T1018: {
    id: 'T1018',
    name: 'Remote System Discovery',
    description: 'Remote System Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1018`
  },
  T1020: {
    id: 'T1020',
    name: 'Automated Exfiltration',
    description: 'Automated Exfiltration',
    reference: `${MITRE_TECHNIQUE_SITE}/T1020`
  },
  'T1020.001': {
    id: 'T1020.001',
    name: 'Automated Exfiltration: Traffic Duplication',
    description: 'Automated Exfiltration: Traffic Duplication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1020/001`
  },
  T1021: {
    id: 'T1021',
    name: 'Remote Services',
    description: 'Remote Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021`
  },
  'T1021.001': {
    id: 'T1021.001',
    name: 'Remote Services: Remote Desktop Protocol',
    description: 'Remote Services: Remote Desktop Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/001`
  },
  'T1021.002': {
    id: 'T1021.002',
    name: 'Remote Services: SMB/Windows Admin Shares',
    description: 'Remote Services: SMB/Windows Admin Shares',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/002`
  },
  'T1021.003': {
    id: 'T1021.003',
    name: 'Remote Services: Distributed Component Object Model',
    description: 'Remote Services: Distributed Component Object Model',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/003`
  },
  'T1021.004': {
    id: 'T1021.004',
    name: 'Remote Services: SSH',
    description: 'Remote Services: SSH',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/004`
  },
  'T1021.005': {
    id: 'T1021.005',
    name: 'Remote Services: VNC',
    description: 'Remote Services: VNC',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/005`
  },
  'T1021.006': {
    id: 'T1021.006',
    name: 'Remote Services: Windows Remote Management',
    description: 'Remote Services: Windows Remote Management',
    reference: `${MITRE_TECHNIQUE_SITE}/T1021/006`
  },
  T1025: {
    id: 'T1025',
    name: 'Data from Removable Media',
    description: 'Data from Removable Media',
    reference: `${MITRE_TECHNIQUE_SITE}/T1025`
  },
  T1027: {
    id: 'T1027',
    name: 'Obfuscated Files or Information',
    description: 'Obfuscated Files or Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027`
  },
  'T1027.001': {
    id: 'T1027.001',
    name: 'Obfuscated Files or Information: Binary Padding',
    description: 'Obfuscated Files or Information: Binary Padding',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027/001`
  },
  'T1027.002': {
    id: 'T1027.002',
    name: 'Obfuscated Files or Information: Software Packing',
    description: 'Obfuscated Files or Information: Software Packing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027/002`
  },
  'T1027.003': {
    id: 'T1027.003',
    name: 'Obfuscated Files or Information: Steganography',
    description: 'Obfuscated Files or Information: Steganography',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027/003`
  },
  'T1027.004': {
    id: 'T1027.004',
    name: 'Obfuscated Files or Information: Compile After Delivery',
    description: 'Obfuscated Files or Information: Compile After Delivery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027/004`
  },
  'T1027.005': {
    id: 'T1027.005',
    name: 'Obfuscated Files or Information: Indicator Removal from Tools',
    description:
      'Obfuscated Files or Information: Indicator Removal from Tools',
    reference: `${MITRE_TECHNIQUE_SITE}/T1027/005`
  },
  T1029: {
    id: 'T1029',
    name: 'Scheduled Transfer',
    description: 'Scheduled Transfer',
    reference: `${MITRE_TECHNIQUE_SITE}/T1029`
  },
  T1030: {
    id: 'T1030',
    name: 'Data Transfer Size Limits',
    description: 'Data Transfer Size Limits',
    reference: `${MITRE_TECHNIQUE_SITE}/T1030`
  },
  T1033: {
    id: 'T1033',
    name: 'System Owner/User Discovery',
    description: 'System Owner/User Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1033`
  },
  T1036: {
    id: 'T1036',
    name: 'Masquerading',
    description: 'Masquerading',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036`
  },
  'T1036.001': {
    id: 'T1036.001',
    name: 'Masquerading: Invalid Code Signature',
    description: 'Masquerading: Invalid Code Signature',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/001`
  },
  'T1036.002': {
    id: 'T1036.002',
    name: 'Masquerading: Right-to-Left Override',
    description: 'Masquerading: Right-to-Left Override',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/002`
  },
  'T1036.003': {
    id: 'T1036.003',
    name: 'Masquerading: Rename System Utilities',
    description: 'Masquerading: Rename System Utilities',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/003`
  },
  'T1036.004': {
    id: 'T1036.004',
    name: 'Masquerading: Masquerade Task or Service',
    description: 'Masquerading: Masquerade Task or Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/004`
  },
  'T1036.005': {
    id: 'T1036.005',
    name: 'Masquerading: Match Legitimate Name or Location',
    description: 'Masquerading: Match Legitimate Name or Location',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/005`
  },
  'T1036.006': {
    id: 'T1036.006',
    name: 'Masquerading: Space after Filename',
    description: 'Masquerading: Space after Filename',
    reference: `${MITRE_TECHNIQUE_SITE}/T1036/006`
  },
  T1037: {
    id: 'T1037',
    name: 'Boot or Logon Initialization Scripts',
    description: 'Boot or Logon Initialization Scripts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037`
  },
  'T1037.001': {
    id: 'T1037.001',
    name: 'Boot or Logon Initialization Scripts: Logon Script (Windows)',
    description: 'Boot or Logon Initialization Scripts: Logon Script (Windows)',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037/001`
  },
  'T1037.002': {
    id: 'T1037.002',
    name: 'Boot or Logon Initialization Scripts: Logon Script (Mac)',
    description: 'Boot or Logon Initialization Scripts: Logon Script (Mac)',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037/002`
  },
  'T1037.003': {
    id: 'T1037.003',
    name: 'Boot or Logon Initialization Scripts: Network Logon Script',
    description: 'Boot or Logon Initialization Scripts: Network Logon Script',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037/003`
  },
  'T1037.004': {
    id: 'T1037.004',
    name: 'Boot or Logon Initialization Scripts: RC Scripts',
    description: 'Boot or Logon Initialization Scripts: RC Scripts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037/004`
  },
  'T1037.005': {
    id: 'T1037.005',
    name: 'Boot or Logon Initialization Scripts: Startup Items',
    description: 'Boot or Logon Initialization Scripts: Startup Items',
    reference: `${MITRE_TECHNIQUE_SITE}/T1037/005`
  },
  T1039: {
    id: 'T1039',
    name: 'Data from Network Shared Drive',
    description: 'Data from Network Shared Drive',
    reference: `${MITRE_TECHNIQUE_SITE}/T1039`
  },
  T1040: {
    id: 'T1040',
    name: 'Network Sniffing',
    description: 'Network Sniffing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1040`
  },
  T1041: {
    id: 'T1041',
    name: 'Exfiltration Over C2 Channel',
    description: 'Exfiltration Over C2 Channel',
    reference: `${MITRE_TECHNIQUE_SITE}/T1041`
  },
  T1046: {
    id: 'T1046',
    name: 'Network Service Scanning',
    description: 'Network Service Scanning',
    reference: `${MITRE_TECHNIQUE_SITE}/T1046`
  },
  T1047: {
    id: 'T1047',
    name: 'Windows Management Instrumentation',
    description: 'Windows Management Instrumentation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1047`
  },
  T1048: {
    id: 'T1048',
    name: 'Exfiltration Over Alternative Protocol',
    description: 'Exfiltration Over Alternative Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1048`
  },
  'T1048.001': {
    id: 'T1048.001',
    name: 'Exfiltration Over Alternative Protocol: Exfiltration Over Symmetric Encrypted Non-C2 Protocol',
    description:
      'Exfiltration Over Alternative Protocol: Exfiltration Over Symmetric Encrypted Non-C2 Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1048/001`
  },
  'T1048.002': {
    id: 'T1048.002',
    name: 'Exfiltration Over Alternative Protocol: Exfiltration Over Asymmetric Encrypted Non-C2 Protocol',
    description:
      'Exfiltration Over Alternative Protocol: Exfiltration Over Asymmetric Encrypted Non-C2 Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1048/002`
  },
  'T1048.003': {
    id: 'T1048.003',
    name: 'Exfiltration Over Alternative Protocol: Exfiltration Over Unencrypted/Obfuscated Non-C2 Protocol',
    description:
      'Exfiltration Over Alternative Protocol: Exfiltration Over Unencrypted/Obfuscated Non-C2 Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1048/003`
  },
  T1049: {
    id: 'T1049',
    name: 'System Network Connections Discovery',
    description: 'System Network Connections Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1049`
  },
  T1052: {
    id: 'T1052',
    name: 'Exfiltration Over Physical Medium',
    description: 'Exfiltration Over Physical Medium',
    reference: `${MITRE_TECHNIQUE_SITE}/T1052`
  },
  'T1052.001': {
    id: 'T1052.001',
    name: 'Exfiltration Over Physical Medium: Exfiltration over USB',
    description: 'Exfiltration Over Physical Medium: Exfiltration over USB',
    reference: `${MITRE_TECHNIQUE_SITE}/T1052/001`
  },
  T1053: {
    id: 'T1053',
    name: 'Scheduled Task/Job',
    description: 'Scheduled Task/Job',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053`
  },
  'T1053.001': {
    id: 'T1053.001',
    name: 'Scheduled Task/Job: At (Linux)',
    description: 'Scheduled Task/Job: At (Linux)',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/001`
  },
  'T1053.002': {
    id: 'T1053.002',
    name: 'Scheduled Task/Job: At (Windows)',
    description: 'Scheduled Task/Job: At (Windows)',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/002`
  },
  'T1053.003': {
    id: 'T1053.003',
    name: 'Scheduled Task/Job: Cron',
    description: 'Scheduled Task/Job: Cron',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/003`
  },
  'T1053.004': {
    id: 'T1053.004',
    name: 'Scheduled Task/Job: Launchd',
    description: 'Scheduled Task/Job: Launchd',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/004`
  },
  'T1053.005': {
    id: 'T1053.005',
    name: 'Scheduled Task/Job: Scheduled Task',
    description: 'Scheduled Task/Job: Scheduled Task',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/005`
  },
  'T1053.006': {
    id: 'T1053.006',
    name: 'Scheduled Task/Job: Systemd Timers',
    description: 'Scheduled Task/Job: Systemd Timers',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/006`
  },
  'T1053.007': {
    id: 'T1053.007',
    name: 'Scheduled Task/Job: Container Orchestration Job',
    description: 'Scheduled Task/Job: Container Orchestration Job',
    reference: `${MITRE_TECHNIQUE_SITE}/T1053/007`
  },
  T1055: {
    id: 'T1055',
    name: 'Process Injection',
    description: 'Process Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055`
  },
  'T1055.001': {
    id: 'T1055.001',
    name: 'Process Injection: Dynamic-link Library Injection',
    description: 'Process Injection: Dynamic-link Library Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/001`
  },
  'T1055.002': {
    id: 'T1055.002',
    name: 'Process Injection: Portable Executable Injection',
    description: 'Process Injection: Portable Executable Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/002`
  },
  'T1055.003': {
    id: 'T1055.003',
    name: 'Process Injection: Thread Execution Hijacking',
    description: 'Process Injection: Thread Execution Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/003`
  },
  'T1055.004': {
    id: 'T1055.004',
    name: 'Process Injection: Asynchronous Procedure Call',
    description: 'Process Injection: Asynchronous Procedure Call',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/004`
  },
  'T1055.005': {
    id: 'T1055.005',
    name: 'Process Injection: Thread Local Storage',
    description: 'Process Injection: Thread Local Storage',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/005`
  },
  'T1055.008': {
    id: 'T1055.008',
    name: 'Process Injection: Ptrace System Calls',
    description: 'Process Injection: Ptrace System Calls',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/008`
  },
  'T1055.009': {
    id: 'T1055.009',
    name: 'Process Injection: Proc Memory',
    description: 'Process Injection: Proc Memory',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/009`
  },
  'T1055.011': {
    id: 'T1055.011',
    name: 'Process Injection: Extra Window Memory Injection',
    description: 'Process Injection: Extra Window Memory Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/011`
  },
  'T1055.012': {
    id: 'T1055.012',
    name: 'Process Injection: Process Hollowing',
    description: 'Process Injection: Process Hollowing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/012`
  },
  'T1055.013': {
    id: 'T1055.013',
    name: 'Process Injection: Process Doppelgänging',
    description: 'Process Injection: Process Doppelgänging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/013`
  },
  'T1055.014': {
    id: 'T1055.014',
    name: 'Process Injection: VDSO Hijacking',
    description: 'Process Injection: VDSO Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1055/014`
  },
  T1056: {
    id: 'T1056',
    name: 'Input Capture',
    description: 'Input Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1056`
  },
  'T1056.001': {
    id: 'T1056.001',
    name: 'Input Capture: Keylogging',
    description: 'Input Capture: Keylogging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1056/001`
  },
  'T1056.002': {
    id: 'T1056.002',
    name: 'Input Capture: GUI Input Capture',
    description: 'Input Capture: GUI Input Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1056/002`
  },
  'T1056.003': {
    id: 'T1056.003',
    name: 'Input Capture: Web Portal Capture',
    description: 'Input Capture: Web Portal Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1056/003`
  },
  'T1056.004': {
    id: 'T1056.004',
    name: 'Input Capture: Credential API Hooking',
    description: 'Input Capture: Credential API Hooking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1056/004`
  },
  T1057: {
    id: 'T1057',
    name: 'Process Discovery',
    description: 'Process Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1057`
  },
  T1059: {
    id: 'T1059',
    name: 'Command and Scripting Interpreter',
    description: 'Command and Scripting Interpreter',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059`
  },
  'T1059.001': {
    id: 'T1059.001',
    name: 'Command and Scripting Interpreter: PowerShell',
    description: 'Command and Scripting Interpreter: PowerShell',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/001`
  },
  'T1059.002': {
    id: 'T1059.002',
    name: 'Command and Scripting Interpreter: AppleScript',
    description: 'Command and Scripting Interpreter: AppleScript',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/002`
  },
  'T1059.003': {
    id: 'T1059.003',
    name: 'Command and Scripting Interpreter: Windows Command Shell',
    description: 'Command and Scripting Interpreter: Windows Command Shell',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/003`
  },
  'T1059.004': {
    id: 'T1059.004',
    name: 'Command and Scripting Interpreter: Unix Shell',
    description: 'Command and Scripting Interpreter: Unix Shell',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/004`
  },
  'T1059.005': {
    id: 'T1059.005',
    name: 'Command and Scripting Interpreter: Visual Basic',
    description: 'Command and Scripting Interpreter: Visual Basic',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/005`
  },
  'T1059.006': {
    id: 'T1059.006',
    name: 'Command and Scripting Interpreter: Python',
    description: 'Command and Scripting Interpreter: Python',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/006`
  },
  'T1059.007': {
    id: 'T1059.007',
    name: 'Command and Scripting Interpreter: JavaScript',
    description: 'Command and Scripting Interpreter: JavaScript',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/007`
  },
  'T1059.008': {
    id: 'T1059.008',
    name: 'Command and Scripting Interpreter: Network Device CLI',
    description: 'Command and Scripting Interpreter: Network Device CLI',
    reference: `${MITRE_TECHNIQUE_SITE}/T1059/008`
  },
  T1068: {
    id: 'T1068',
    name: 'Exploitation for Privilege Escalation',
    description: 'Exploitation for Privilege Escalation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1068`
  },
  T1069: {
    id: 'T1069',
    name: 'Permission Groups Discovery',
    description: 'Permission Groups Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1069`
  },
  'T1069.001': {
    id: 'T1069.001',
    name: 'Permission Groups Discovery: Local Groups',
    description: 'Permission Groups Discovery: Local Groups',
    reference: `${MITRE_TECHNIQUE_SITE}/T1069/001`
  },
  'T1069.002': {
    id: 'T1069.002',
    name: 'Permission Groups Discovery: Domain Groups',
    description: 'Permission Groups Discovery: Domain Groups',
    reference: `${MITRE_TECHNIQUE_SITE}/T1069/002`
  },
  'T1069.003': {
    id: 'T1069.003',
    name: 'Permission Groups Discovery: Cloud Groups',
    description: 'Permission Groups Discovery: Cloud Groups',
    reference: `${MITRE_TECHNIQUE_SITE}/T1069/003`
  },
  T1070: {
    id: 'T1070',
    name: 'Indicator Removal on Host',
    description: 'Indicator Removal on Host',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070`
  },
  'T1070.001': {
    id: 'T1070.001',
    name: 'Indicator Removal on Host: Clear Windows Event Logs',
    description: 'Indicator Removal on Host: Clear Windows Event Logs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/001`
  },
  'T1070.002': {
    id: 'T1070.002',
    name: 'Indicator Removal on Host: Clear Linux or Mac System Logs',
    description: 'Indicator Removal on Host: Clear Linux or Mac System Logs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/002`
  },
  'T1070.003': {
    id: 'T1070.003',
    name: 'Indicator Removal on Host: Clear Command History',
    description: 'Indicator Removal on Host: Clear Command History',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/003`
  },
  'T1070.004': {
    id: 'T1070.004',
    name: 'Indicator Removal on Host: File Deletion',
    description: 'Indicator Removal on Host: File Deletion',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/004`
  },
  'T1070.005': {
    id: 'T1070.005',
    name: 'Indicator Removal on Host: Network Share Connection Removal',
    description: 'Indicator Removal on Host: Network Share Connection Removal',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/005`
  },
  'T1070.006': {
    id: 'T1070.006',
    name: 'Indicator Removal on Host: Timestomp',
    description: 'Indicator Removal on Host: Timestomp',
    reference: `${MITRE_TECHNIQUE_SITE}/T1070/006`
  },
  T1071: {
    id: 'T1071',
    name: 'Application Layer Protocol',
    description: 'Application Layer Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1071`
  },
  'T1071.001': {
    id: 'T1071.001',
    name: 'Application Layer Protocol: Web Protocols',
    description: 'Application Layer Protocol: Web Protocols',
    reference: `${MITRE_TECHNIQUE_SITE}/T1071/001`
  },
  'T1071.002': {
    id: 'T1071.002',
    name: 'Application Layer Protocol: File Transfer Protocols',
    description: 'Application Layer Protocol: File Transfer Protocols',
    reference: `${MITRE_TECHNIQUE_SITE}/T1071/002`
  },
  'T1071.003': {
    id: 'T1071.003',
    name: 'Application Layer Protocol: Mail Protocols',
    description: 'Application Layer Protocol: Mail Protocols',
    reference: `${MITRE_TECHNIQUE_SITE}/T1071/003`
  },
  'T1071.004': {
    id: 'T1071.004',
    name: 'Application Layer Protocol: DNS',
    description: 'Application Layer Protocol: DNS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1071/004`
  },
  T1072: {
    id: 'T1072',
    name: 'Software Deployment Tools',
    description: 'Software Deployment Tools',
    reference: `${MITRE_TECHNIQUE_SITE}/T1072`
  },
  T1074: {
    id: 'T1074',
    name: 'Data Staged',
    description: 'Data Staged',
    reference: `${MITRE_TECHNIQUE_SITE}/T1074`
  },
  'T1074.001': {
    id: 'T1074.001',
    name: 'Data Staged: Local Data Staging',
    description: 'Data Staged: Local Data Staging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1074/001`
  },
  'T1074.002': {
    id: 'T1074.002',
    name: 'Data Staged: Remote Data Staging',
    description: 'Data Staged: Remote Data Staging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1074/002`
  },
  T1078: {
    id: 'T1078',
    name: 'Valid Accounts',
    description: 'Valid Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1078`
  },
  'T1078.001': {
    id: 'T1078.001',
    name: 'Valid Accounts: Default Accounts',
    description: 'Valid Accounts: Default Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1078/001`
  },
  'T1078.002': {
    id: 'T1078.002',
    name: 'Valid Accounts: Domain Accounts',
    description: 'Valid Accounts: Domain Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1078/002`
  },
  'T1078.003': {
    id: 'T1078.003',
    name: 'Valid Accounts: Local Accounts',
    description: 'Valid Accounts: Local Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1078/003`
  },
  'T1078.004': {
    id: 'T1078.004',
    name: 'Valid Accounts: Cloud Accounts',
    description: 'Valid Accounts: Cloud Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1078/004`
  },
  T1080: {
    id: 'T1080',
    name: 'Taint Shared Content',
    description: 'Taint Shared Content',
    reference: `${MITRE_TECHNIQUE_SITE}/T1080`
  },
  T1082: {
    id: 'T1082',
    name: 'System Information Discovery',
    description: 'System Information Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1082`
  },
  T1083: {
    id: 'T1083',
    name: 'File and Directory Discovery',
    description: 'File and Directory Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1083`
  },
  T1087: {
    id: 'T1087',
    name: 'Account Discovery',
    description: 'Account Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1087`
  },
  'T1087.001': {
    id: 'T1087.001',
    name: 'Account Discovery: Local Account',
    description: 'Account Discovery: Local Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1087/001`
  },
  'T1087.002': {
    id: 'T1087.002',
    name: 'Account Discovery: Domain Account',
    description: 'Account Discovery: Domain Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1087/002`
  },
  'T1087.003': {
    id: 'T1087.003',
    name: 'Account Discovery: Email Account',
    description: 'Account Discovery: Email Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1087/003`
  },
  'T1087.004': {
    id: 'T1087.004',
    name: 'Account Discovery: Cloud Account',
    description: 'Account Discovery: Cloud Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1087/004`
  },
  T1090: {
    id: 'T1090',
    name: 'Proxy',
    description: 'Proxy',
    reference: `${MITRE_TECHNIQUE_SITE}/T1090`
  },
  'T1090.001': {
    id: 'T1090.001',
    name: 'Proxy: Internal Proxy',
    description: 'Proxy: Internal Proxy',
    reference: `${MITRE_TECHNIQUE_SITE}/T1090/001`
  },
  'T1090.002': {
    id: 'T1090.002',
    name: 'Proxy: External Proxy',
    description: 'Proxy: External Proxy',
    reference: `${MITRE_TECHNIQUE_SITE}/T1090/002`
  },
  'T1090.003': {
    id: 'T1090.003',
    name: 'Proxy: Multi-hop Proxy',
    description: 'Proxy: Multi-hop Proxy',
    reference: `${MITRE_TECHNIQUE_SITE}/T1090/003`
  },
  'T1090.004': {
    id: 'T1090.004',
    name: 'Proxy: Domain Fronting',
    description: 'Proxy: Domain Fronting',
    reference: `${MITRE_TECHNIQUE_SITE}/T1090/004`
  },
  T1091: {
    id: 'T1091',
    name: 'Replication Through Removable Media',
    description: 'Replication Through Removable Media',
    reference: `${MITRE_TECHNIQUE_SITE}/T1091`
  },
  T1092: {
    id: 'T1092',
    name: 'Communication Through Removable Media',
    description: 'Communication Through Removable Media',
    reference: `${MITRE_TECHNIQUE_SITE}/T1092`
  },
  T1095: {
    id: 'T1095',
    name: 'Non-Application Layer Protocol',
    description: 'Non-Application Layer Protocol',
    reference: `${MITRE_TECHNIQUE_SITE}/T1095`
  },
  T1098: {
    id: 'T1098',
    name: 'Account Manipulation',
    description: 'Account Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1098`
  },
  'T1098.001': {
    id: 'T1098.001',
    name: 'Account Manipulation: Additional Cloud Credentials',
    description: 'Account Manipulation: Additional Cloud Credentials',
    reference: `${MITRE_TECHNIQUE_SITE}/T1098/001`
  },
  'T1098.002': {
    id: 'T1098.002',
    name: 'Account Manipulation: Exchange Email Delegate Permissions',
    description: 'Account Manipulation: Exchange Email Delegate Permissions',
    reference: `${MITRE_TECHNIQUE_SITE}/T1098/002`
  },
  'T1098.003': {
    id: 'T1098.003',
    name: 'Account Manipulation: Add Office 365 Global Administrator Role',
    description:
      'Account Manipulation: Add Office 365 Global Administrator Role',
    reference: `${MITRE_TECHNIQUE_SITE}/T1098/003`
  },
  'T1098.004': {
    id: 'T1098.004',
    name: 'Account Manipulation: SSH Authorized Keys',
    description: 'Account Manipulation: SSH Authorized Keys',
    reference: `${MITRE_TECHNIQUE_SITE}/T1098/004`
  },
  T1102: {
    id: 'T1102',
    name: 'Web Service',
    description: 'Web Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1102`
  },
  'T1102.001': {
    id: 'T1102.001',
    name: 'Web Service: Dead Drop Resolver',
    description: 'Web Service: Dead Drop Resolver',
    reference: `${MITRE_TECHNIQUE_SITE}/T1102/001`
  },
  'T1102.002': {
    id: 'T1102.002',
    name: 'Web Service: Bidirectional Communication',
    description: 'Web Service: Bidirectional Communication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1102/002`
  },
  'T1102.003': {
    id: 'T1102.003',
    name: 'Web Service: One-Way Communication',
    description: 'Web Service: One-Way Communication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1102/003`
  },
  T1104: {
    id: 'T1104',
    name: 'Multi-Stage Channels',
    description: 'Multi-Stage Channels',
    reference: `${MITRE_TECHNIQUE_SITE}/T1104`
  },
  T1105: {
    id: 'T1105',
    name: 'Ingress Tool Transfer',
    description: 'Ingress Tool Transfer',
    reference: `${MITRE_TECHNIQUE_SITE}/T1105`
  },
  T1106: {
    id: 'T1106',
    name: 'Native API',
    description: 'Native API',
    reference: `${MITRE_TECHNIQUE_SITE}/T1106`
  },
  T1110: {
    id: 'T1110',
    name: 'Brute Force',
    description: 'Brute Force',
    reference: `${MITRE_TECHNIQUE_SITE}/T1110`
  },
  'T1110.001': {
    id: 'T1110.001',
    name: 'Brute Force: Password Guessing',
    description: 'Brute Force: Password Guessing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1110/001`
  },
  'T1110.002': {
    id: 'T1110.002',
    name: 'Brute Force: Password Cracking',
    description: 'Brute Force: Password Cracking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1110/002`
  },
  'T1110.003': {
    id: 'T1110.003',
    name: 'Brute Force: Password Spraying',
    description: 'Brute Force: Password Spraying',
    reference: `${MITRE_TECHNIQUE_SITE}/T1110/003`
  },
  'T1110.004': {
    id: 'T1110.004',
    name: 'Brute Force: Credential Stuffing',
    description: 'Brute Force: Credential Stuffing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1110/004`
  },
  T1111: {
    id: 'T1111',
    name: 'Two-Factor Authentication Interception',
    description: 'Two-Factor Authentication Interception',
    reference: `${MITRE_TECHNIQUE_SITE}/T1111`
  },
  T1112: {
    id: 'T1112',
    name: 'Modify Registry',
    description: 'Modify Registry',
    reference: `${MITRE_TECHNIQUE_SITE}/T1112`
  },
  T1113: {
    id: 'T1113',
    name: 'Screen Capture',
    description: 'Screen Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1113`
  },
  T1114: {
    id: 'T1114',
    name: 'Email Collection',
    description: 'Email Collection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1114`
  },
  'T1114.001': {
    id: 'T1114.001',
    name: 'Email Collection: Local Email Collection',
    description: 'Email Collection: Local Email Collection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1114/001`
  },
  'T1114.002': {
    id: 'T1114.002',
    name: 'Email Collection: Remote Email Collection',
    description: 'Email Collection: Remote Email Collection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1114/002`
  },
  'T1114.003': {
    id: 'T1114.003',
    name: 'Email Collection: Email Forwarding Rule',
    description: 'Email Collection: Email Forwarding Rule',
    reference: `${MITRE_TECHNIQUE_SITE}/T1114/003`
  },
  T1115: {
    id: 'T1115',
    name: 'Clipboard Data',
    description: 'Clipboard Data',
    reference: `${MITRE_TECHNIQUE_SITE}/T1115`
  },
  T1119: {
    id: 'T1119',
    name: 'Automated Collection',
    description: 'Automated Collection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1119`
  },
  T1120: {
    id: 'T1120',
    name: 'Peripheral Device Discovery',
    description: 'Peripheral Device Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1120`
  },
  T1123: {
    id: 'T1123',
    name: 'Audio Capture',
    description: 'Audio Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1123`
  },
  T1124: {
    id: 'T1124',
    name: 'System Time Discovery',
    description: 'System Time Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1124`
  },
  T1125: {
    id: 'T1125',
    name: 'Video Capture',
    description: 'Video Capture',
    reference: `${MITRE_TECHNIQUE_SITE}/T1125`
  },
  T1127: {
    id: 'T1127',
    name: 'Trusted Developer Utilities Proxy Execution',
    description: 'Trusted Developer Utilities Proxy Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1127`
  },
  'T1127.001': {
    id: 'T1127.001',
    name: 'Trusted Developer Utilities Proxy Execution: MSBuild',
    description: 'Trusted Developer Utilities Proxy Execution: MSBuild',
    reference: `${MITRE_TECHNIQUE_SITE}/T1127/001`
  },
  T1129: {
    id: 'T1129',
    name: 'Shared Modules',
    description: 'Shared Modules',
    reference: `${MITRE_TECHNIQUE_SITE}/T1129`
  },
  T1132: {
    id: 'T1132',
    name: 'Data Encoding',
    description: 'Data Encoding',
    reference: `${MITRE_TECHNIQUE_SITE}/T1132`
  },
  'T1132.001': {
    id: 'T1132.001',
    name: 'Data Encoding: Standard Encoding',
    description: 'Data Encoding: Standard Encoding',
    reference: `${MITRE_TECHNIQUE_SITE}/T1132/001`
  },
  'T1132.002': {
    id: 'T1132.002',
    name: 'Data Encoding: Non-Standard Encoding',
    description: 'Data Encoding: Non-Standard Encoding',
    reference: `${MITRE_TECHNIQUE_SITE}/T1132/002`
  },
  T1133: {
    id: 'T1133',
    name: 'External Remote Services',
    description: 'External Remote Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1133`
  },
  T1134: {
    id: 'T1134',
    name: 'Access Token Manipulation',
    description: 'Access Token Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134`
  },
  'T1134.001': {
    id: 'T1134.001',
    name: 'Access Token Manipulation: Token Impersonation/Theft',
    description: 'Access Token Manipulation: Token Impersonation/Theft',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134/001`
  },
  'T1134.002': {
    id: 'T1134.002',
    name: 'Access Token Manipulation: Create Process with Token',
    description: 'Access Token Manipulation: Create Process with Token',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134/002`
  },
  'T1134.003': {
    id: 'T1134.003',
    name: 'Access Token Manipulation: Make and Impersonate Token',
    description: 'Access Token Manipulation: Make and Impersonate Token',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134/003`
  },
  'T1134.004': {
    id: 'T1134.004',
    name: 'Access Token Manipulation: Parent PID Spoofing',
    description: 'Access Token Manipulation: Parent PID Spoofing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134/004`
  },
  'T1134.005': {
    id: 'T1134.005',
    name: 'Access Token Manipulation: SID-History Injection',
    description: 'Access Token Manipulation: SID-History Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1134/005`
  },
  T1135: {
    id: 'T1135',
    name: 'Network Share Discovery',
    description: 'Network Share Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1135`
  },
  T1136: {
    id: 'T1136',
    name: 'Create Account',
    description: 'Create Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1136`
  },
  'T1136.001': {
    id: 'T1136.001',
    name: 'Create Account: Local Account',
    description: 'Create Account: Local Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1136/001`
  },
  'T1136.002': {
    id: 'T1136.002',
    name: 'Create Account: Domain Account',
    description: 'Create Account: Domain Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1136/002`
  },
  'T1136.003': {
    id: 'T1136.003',
    name: 'Create Account: Cloud Account',
    description: 'Create Account: Cloud Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1136/003`
  },
  T1137: {
    id: 'T1137',
    name: 'Office Application Startup',
    description: 'Office Application Startup',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137`
  },
  'T1137.001': {
    id: 'T1137.001',
    name: 'Office Application Startup: Office Template Macros',
    description: 'Office Application Startup: Office Template Macros',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/001`
  },
  'T1137.002': {
    id: 'T1137.002',
    name: 'Office Application Startup: Office Test',
    description: 'Office Application Startup: Office Test',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/002`
  },
  'T1137.003': {
    id: 'T1137.003',
    name: 'Office Application Startup: Outlook Forms',
    description: 'Office Application Startup: Outlook Forms',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/003`
  },
  'T1137.004': {
    id: 'T1137.004',
    name: 'Office Application Startup: Outlook Home Page',
    description: 'Office Application Startup: Outlook Home Page',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/004`
  },
  'T1137.005': {
    id: 'T1137.005',
    name: 'Office Application Startup: Outlook Rules',
    description: 'Office Application Startup: Outlook Rules',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/005`
  },
  'T1137.006': {
    id: 'T1137.006',
    name: 'Office Application Startup: Add-ins',
    description: 'Office Application Startup: Add-ins',
    reference: `${MITRE_TECHNIQUE_SITE}/T1137/006`
  },
  T1140: {
    id: 'T1140',
    name: 'Deobfuscate/Decode Files or Information',
    description: 'Deobfuscate/Decode Files or Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1140`
  },
  T1176: {
    id: 'T1176',
    name: 'Browser Extensions',
    description: 'Browser Extensions',
    reference: `${MITRE_TECHNIQUE_SITE}/T1176`
  },
  T1185: {
    id: 'T1185',
    name: 'Man in the Browser',
    description: 'Man in the Browser',
    reference: `${MITRE_TECHNIQUE_SITE}/T1185`
  },
  T1187: {
    id: 'T1187',
    name: 'Forced Authentication',
    description: 'Forced Authentication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1187`
  },
  T1189: {
    id: 'T1189',
    name: 'Drive-by Compromise',
    description: 'Drive-by Compromise',
    reference: `${MITRE_TECHNIQUE_SITE}/T1189`
  },
  T1190: {
    id: 'T1190',
    name: 'Exploit Public-Facing Application',
    description: 'Exploit Public-Facing Application',
    reference: `${MITRE_TECHNIQUE_SITE}/T1190`
  },
  T1195: {
    id: 'T1195',
    name: 'Supply Chain Compromise',
    description: 'Supply Chain Compromise',
    reference: `${MITRE_TECHNIQUE_SITE}/T1195`
  },
  'T1195.001': {
    id: 'T1195.001',
    name: 'Supply Chain Compromise: Compromise Software Dependencies and Development Tools',
    description:
      'Supply Chain Compromise: Compromise Software Dependencies and Development Tools',
    reference: `${MITRE_TECHNIQUE_SITE}/T1195/001`
  },
  'T1195.002': {
    id: 'T1195.002',
    name: 'Supply Chain Compromise: Compromise Software Supply Chain',
    description: 'Supply Chain Compromise: Compromise Software Supply Chain',
    reference: `${MITRE_TECHNIQUE_SITE}/T1195/002`
  },
  'T1195.003': {
    id: 'T1195.003',
    name: 'Supply Chain Compromise: Compromise Hardware Supply Chain',
    description: 'Supply Chain Compromise: Compromise Hardware Supply Chain',
    reference: `${MITRE_TECHNIQUE_SITE}/T1195/003`
  },
  T1197: {
    id: 'T1197',
    name: 'BITS Jobs',
    description: 'BITS Jobs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1197`
  },
  T1199: {
    id: 'T1199',
    name: 'Trusted Relationship',
    description: 'Trusted Relationship',
    reference: `${MITRE_TECHNIQUE_SITE}/T1199`
  },
  T1200: {
    id: 'T1200',
    name: 'Hardware Additions',
    description: 'Hardware Additions',
    reference: `${MITRE_TECHNIQUE_SITE}/T1200`
  },
  T1201: {
    id: 'T1201',
    name: 'Password Policy Discovery',
    description: 'Password Policy Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1201`
  },
  T1202: {
    id: 'T1202',
    name: 'Indirect Command Execution',
    description: 'Indirect Command Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1202`
  },
  T1203: {
    id: 'T1203',
    name: 'Exploitation for Client Execution',
    description: 'Exploitation for Client Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1203`
  },
  T1204: {
    id: 'T1204',
    name: 'User Execution',
    description: 'User Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1204`
  },
  'T1204.001': {
    id: 'T1204.001',
    name: 'User Execution: Malicious Link',
    description: 'User Execution: Malicious Link',
    reference: `${MITRE_TECHNIQUE_SITE}/T1204/001`
  },
  'T1204.002': {
    id: 'T1204.002',
    name: 'User Execution: Malicious File',
    description: 'User Execution: Malicious File',
    reference: `${MITRE_TECHNIQUE_SITE}/T1204/002`
  },
  'T1204.003': {
    id: 'T1204.003',
    name: 'User Execution: Malicious Image',
    description: 'User Execution: Malicious Image',
    reference: `${MITRE_TECHNIQUE_SITE}/T1204/003`
  },
  T1205: {
    id: 'T1205',
    name: 'Traffic Signaling',
    description: 'Traffic Signaling',
    reference: `${MITRE_TECHNIQUE_SITE}/T1205`
  },
  'T1205.001': {
    id: 'T1205.001',
    name: 'Traffic Signaling: Port Knocking',
    description: 'Traffic Signaling: Port Knocking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1205/001`
  },
  T1207: {
    id: 'T1207',
    name: 'Rogue Domain Controller',
    description: 'Rogue Domain Controller',
    reference: `${MITRE_TECHNIQUE_SITE}/T1207`
  },
  T1210: {
    id: 'T1210',
    name: 'Exploitation of Remote Services',
    description: 'Exploitation of Remote Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1210`
  },
  T1211: {
    id: 'T1211',
    name: 'Exploitation for Defense Evasion',
    description: 'Exploitation for Defense Evasion',
    reference: `${MITRE_TECHNIQUE_SITE}/T1211`
  },
  T1212: {
    id: 'T1212',
    name: 'Exploitation for Credential Access',
    description: 'Exploitation for Credential Access',
    reference: `${MITRE_TECHNIQUE_SITE}/T1212`
  },
  T1213: {
    id: 'T1213',
    name: 'Data from Information Repositories',
    description: 'Data from Information Repositories',
    reference: `${MITRE_TECHNIQUE_SITE}/T1213`
  },
  'T1213.001': {
    id: 'T1213.001',
    name: 'Data from Information Repositories: Confluence',
    description: 'Data from Information Repositories: Confluence',
    reference: `${MITRE_TECHNIQUE_SITE}/T1213/001`
  },
  'T1213.002': {
    id: 'T1213.002',
    name: 'Data from Information Repositories: Sharepoint',
    description: 'Data from Information Repositories: Sharepoint',
    reference: `${MITRE_TECHNIQUE_SITE}/T1213/002`
  },
  T1216: {
    id: 'T1216',
    name: 'Signed Script Proxy Execution',
    description: 'Signed Script Proxy Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1216`
  },
  'T1216.001': {
    id: 'T1216.001',
    name: 'Signed Script Proxy Execution: PubPrn',
    description: 'Signed Script Proxy Execution: PubPrn',
    reference: `${MITRE_TECHNIQUE_SITE}/T1216/001`
  },
  T1217: {
    id: 'T1217',
    name: 'Browser Bookmark Discovery',
    description: 'Browser Bookmark Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1217`
  },
  T1218: {
    id: 'T1218',
    name: 'Signed Binary Proxy Execution',
    description: 'Signed Binary Proxy Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218`
  },
  'T1218.001': {
    id: 'T1218.001',
    name: 'Signed Binary Proxy Execution: Compiled HTML File',
    description: 'Signed Binary Proxy Execution: Compiled HTML File',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/001`
  },
  'T1218.002': {
    id: 'T1218.002',
    name: 'Signed Binary Proxy Execution: Control Panel',
    description: 'Signed Binary Proxy Execution: Control Panel',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/002`
  },
  'T1218.003': {
    id: 'T1218.003',
    name: 'Signed Binary Proxy Execution: CMSTP',
    description: 'Signed Binary Proxy Execution: CMSTP',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/003`
  },
  'T1218.004': {
    id: 'T1218.004',
    name: 'Signed Binary Proxy Execution: InstallUtil',
    description: 'Signed Binary Proxy Execution: InstallUtil',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/004`
  },
  'T1218.005': {
    id: 'T1218.005',
    name: 'Signed Binary Proxy Execution: Mshta',
    description: 'Signed Binary Proxy Execution: Mshta',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/005`
  },
  'T1218.007': {
    id: 'T1218.007',
    name: 'Signed Binary Proxy Execution: Msiexec',
    description: 'Signed Binary Proxy Execution: Msiexec',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/007`
  },
  'T1218.008': {
    id: 'T1218.008',
    name: 'Signed Binary Proxy Execution: Odbcconf',
    description: 'Signed Binary Proxy Execution: Odbcconf',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/008`
  },
  'T1218.009': {
    id: 'T1218.009',
    name: 'Signed Binary Proxy Execution: Regsvcs/Regasm',
    description: 'Signed Binary Proxy Execution: Regsvcs/Regasm',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/009`
  },
  'T1218.010': {
    id: 'T1218.010',
    name: 'Signed Binary Proxy Execution: Regsvr32',
    description: 'Signed Binary Proxy Execution: Regsvr32',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/010`
  },
  'T1218.011': {
    id: 'T1218.011',
    name: 'Signed Binary Proxy Execution: Rundll32',
    description: 'Signed Binary Proxy Execution: Rundll32',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/011`
  },
  'T1218.012': {
    id: 'T1218.012',
    name: 'Signed Binary Proxy Execution: Verclsid',
    description: 'Signed Binary Proxy Execution: Verclsid',
    reference: `${MITRE_TECHNIQUE_SITE}/T1218/012`
  },
  T1219: {
    id: 'T1219',
    name: 'Remote Access Software',
    description: 'Remote Access Software',
    reference: `${MITRE_TECHNIQUE_SITE}/T1219`
  },
  T1220: {
    id: 'T1220',
    name: 'XSL Script Processing',
    description: 'XSL Script Processing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1220`
  },
  T1221: {
    id: 'T1221',
    name: 'Template Injection',
    description: 'Template Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1221`
  },
  T1222: {
    id: 'T1222',
    name: 'File and Directory Permissions Modification',
    description: 'File and Directory Permissions Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1222`
  },
  'T1222.001': {
    id: 'T1222.001',
    name: 'File and Directory Permissions Modification: Windows File and Directory Permissions Modification',
    description:
      'File and Directory Permissions Modification: Windows File and Directory Permissions Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1222/001`
  },
  'T1222.002': {
    id: 'T1222.002',
    name: 'File and Directory Permissions Modification: Linux and Mac File and Directory Permissions Modification',
    description:
      'File and Directory Permissions Modification: Linux and Mac File and Directory Permissions Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1222/002`
  },
  T1480: {
    id: 'T1480',
    name: 'Execution Guardrails',
    description: 'Execution Guardrails',
    reference: `${MITRE_TECHNIQUE_SITE}/T1480`
  },
  'T1480.001': {
    id: 'T1480.001',
    name: 'Execution Guardrails: Environmental Keying',
    description: 'Execution Guardrails: Environmental Keying',
    reference: `${MITRE_TECHNIQUE_SITE}/T1480/001`
  },
  T1482: {
    id: 'T1482',
    name: 'Domain Trust Discovery',
    description: 'Domain Trust Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1482`
  },
  T1484: {
    id: 'T1484',
    name: 'Domain Policy Modification',
    description: 'Domain Policy Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1484`
  },
  'T1484.001': {
    id: 'T1484.001',
    name: 'Domain Policy Modification: Group Policy Modification',
    description: 'Domain Policy Modification: Group Policy Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1484/001`
  },
  'T1484.002': {
    id: 'T1484.002',
    name: 'Domain Policy Modification: Domain Trust Modification',
    description: 'Domain Policy Modification: Domain Trust Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1484/002`
  },
  T1485: {
    id: 'T1485',
    name: 'Data Destruction',
    description: 'Data Destruction',
    reference: `${MITRE_TECHNIQUE_SITE}/T1485`
  },
  T1486: {
    id: 'T1486',
    name: 'Data Encrypted for Impact',
    description: 'Data Encrypted for Impact',
    reference: `${MITRE_TECHNIQUE_SITE}/T1486`
  },
  T1489: {
    id: 'T1489',
    name: 'Service Stop',
    description: 'Service Stop',
    reference: `${MITRE_TECHNIQUE_SITE}/T1489`
  },
  T1490: {
    id: 'T1490',
    name: 'Inhibit System Recovery',
    description: 'Inhibit System Recovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1490`
  },
  T1491: {
    id: 'T1491',
    name: 'Defacement',
    description: 'Defacement',
    reference: `${MITRE_TECHNIQUE_SITE}/T1491`
  },
  'T1491.001': {
    id: 'T1491.001',
    name: 'Defacement: Internal Defacement',
    description: 'Defacement: Internal Defacement',
    reference: `${MITRE_TECHNIQUE_SITE}/T1491/001`
  },
  'T1491.002': {
    id: 'T1491.002',
    name: 'Defacement: External Defacement',
    description: 'Defacement: External Defacement',
    reference: `${MITRE_TECHNIQUE_SITE}/T1491/002`
  },
  T1495: {
    id: 'T1495',
    name: 'Firmware Corruption',
    description: 'Firmware Corruption',
    reference: `${MITRE_TECHNIQUE_SITE}/T1495`
  },
  T1496: {
    id: 'T1496',
    name: 'Resource Hijacking',
    description: 'Resource Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1496`
  },
  T1497: {
    id: 'T1497',
    name: 'Virtualization/Sandbox Evasion',
    description: 'Virtualization/Sandbox Evasion',
    reference: `${MITRE_TECHNIQUE_SITE}/T1497`
  },
  'T1497.001': {
    id: 'T1497.001',
    name: 'Virtualization/Sandbox Evasion: System Checks',
    description: 'Virtualization/Sandbox Evasion: System Checks',
    reference: `${MITRE_TECHNIQUE_SITE}/T1497/001`
  },
  'T1497.002': {
    id: 'T1497.002',
    name: 'Virtualization/Sandbox Evasion: User Activity Based Checks',
    description: 'Virtualization/Sandbox Evasion: User Activity Based Checks',
    reference: `${MITRE_TECHNIQUE_SITE}/T1497/002`
  },
  'T1497.003': {
    id: 'T1497.003',
    name: 'Virtualization/Sandbox Evasion: Time Based Evasion',
    description: 'Virtualization/Sandbox Evasion: Time Based Evasion',
    reference: `${MITRE_TECHNIQUE_SITE}/T1497/003`
  },
  T1498: {
    id: 'T1498',
    name: 'Network Denial of Service',
    description: 'Network Denial of Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1498`
  },
  'T1498.001': {
    id: 'T1498.001',
    name: 'Network Denial of Service: Direct Network Flood',
    description: 'Network Denial of Service: Direct Network Flood',
    reference: `${MITRE_TECHNIQUE_SITE}/T1498/001`
  },
  'T1498.002': {
    id: 'T1498.002',
    name: 'Network Denial of Service: Reflection Amplification',
    description: 'Network Denial of Service: Reflection Amplification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1498/002`
  },
  T1499: {
    id: 'T1499',
    name: 'Endpoint Denial of Service',
    description: 'Endpoint Denial of Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1499`
  },
  'T1499.001': {
    id: 'T1499.001',
    name: 'Endpoint Denial of Service: OS Exhaustion Flood',
    description: 'Endpoint Denial of Service: OS Exhaustion Flood',
    reference: `${MITRE_TECHNIQUE_SITE}/T1499/001`
  },
  'T1499.002': {
    id: 'T1499.002',
    name: 'Endpoint Denial of Service: Service Exhaustion Flood',
    description: 'Endpoint Denial of Service: Service Exhaustion Flood',
    reference: `${MITRE_TECHNIQUE_SITE}/T1499/002`
  },
  'T1499.003': {
    id: 'T1499.003',
    name: 'Endpoint Denial of Service: Application Exhaustion Flood',
    description: 'Endpoint Denial of Service: Application Exhaustion Flood',
    reference: `${MITRE_TECHNIQUE_SITE}/T1499/003`
  },
  'T1499.004': {
    id: 'T1499.004',
    name: 'Endpoint Denial of Service: Application or System Exploitation',
    description:
      'Endpoint Denial of Service: Application or System Exploitation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1499/004`
  },
  T1505: {
    id: 'T1505',
    name: 'Server Software Component',
    description: 'Server Software Component',
    reference: `${MITRE_TECHNIQUE_SITE}/T1505`
  },
  'T1505.001': {
    id: 'T1505.001',
    name: 'Server Software Component: SQL Stored Procedures',
    description: 'Server Software Component: SQL Stored Procedures',
    reference: `${MITRE_TECHNIQUE_SITE}/T1505/001`
  },
  'T1505.002': {
    id: 'T1505.002',
    name: 'Server Software Component: Transport Agent',
    description: 'Server Software Component: Transport Agent',
    reference: `${MITRE_TECHNIQUE_SITE}/T1505/002`
  },
  'T1505.003': {
    id: 'T1505.003',
    name: 'Server Software Component: Web Shell',
    description: 'Server Software Component: Web Shell',
    reference: `${MITRE_TECHNIQUE_SITE}/T1505/003`
  },
  T1518: {
    id: 'T1518',
    name: 'Software Discovery',
    description: 'Software Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1518`
  },
  'T1518.001': {
    id: 'T1518.001',
    name: 'Software Discovery: Security Software Discovery',
    description: 'Software Discovery: Security Software Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1518/001`
  },
  T1525: {
    id: 'T1525',
    name: 'Implant Internal Image',
    description: 'Implant Internal Image',
    reference: `${MITRE_TECHNIQUE_SITE}/T1525`
  },
  T1526: {
    id: 'T1526',
    name: 'Cloud Service Discovery',
    description: 'Cloud Service Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1526`
  },
  T1528: {
    id: 'T1528',
    name: 'Steal Application Access Token',
    description: 'Steal Application Access Token',
    reference: `${MITRE_TECHNIQUE_SITE}/T1528`
  },
  T1529: {
    id: 'T1529',
    name: 'System Shutdown/Reboot',
    description: 'System Shutdown/Reboot',
    reference: `${MITRE_TECHNIQUE_SITE}/T1529`
  },
  T1530: {
    id: 'T1530',
    name: 'Data from Cloud Storage Object',
    description: 'Data from Cloud Storage Object',
    reference: `${MITRE_TECHNIQUE_SITE}/T1530`
  },
  T1531: {
    id: 'T1531',
    name: 'Account Access Removal',
    description: 'Account Access Removal',
    reference: `${MITRE_TECHNIQUE_SITE}/T1531`
  },
  T1534: {
    id: 'T1534',
    name: 'Internal Spearphishing',
    description: 'Internal Spearphishing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1534`
  },
  T1535: {
    id: 'T1535',
    name: 'Unused/Unsupported Cloud Regions',
    description: 'Unused/Unsupported Cloud Regions',
    reference: `${MITRE_TECHNIQUE_SITE}/T1535`
  },
  T1537: {
    id: 'T1537',
    name: 'Transfer Data to Cloud Account',
    description: 'Transfer Data to Cloud Account',
    reference: `${MITRE_TECHNIQUE_SITE}/T1537`
  },
  T1538: {
    id: 'T1538',
    name: 'Cloud Service Dashboard',
    description: 'Cloud Service Dashboard',
    reference: `${MITRE_TECHNIQUE_SITE}/T1538`
  },
  T1539: {
    id: 'T1539',
    name: 'Steal Web Session Cookie',
    description: 'Steal Web Session Cookie',
    reference: `${MITRE_TECHNIQUE_SITE}/T1539`
  },
  T1542: {
    id: 'T1542',
    name: 'Pre-OS Boot',
    description: 'Pre-OS Boot',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542`
  },
  'T1542.001': {
    id: 'T1542.001',
    name: 'Pre-OS Boot: System Firmware',
    description: 'Pre-OS Boot: System Firmware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542/001`
  },
  'T1542.002': {
    id: 'T1542.002',
    name: 'Pre-OS Boot: Component Firmware',
    description: 'Pre-OS Boot: Component Firmware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542/002`
  },
  'T1542.003': {
    id: 'T1542.003',
    name: 'Pre-OS Boot: Bootkit',
    description: 'Pre-OS Boot: Bootkit',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542/003`
  },
  'T1542.004': {
    id: 'T1542.004',
    name: 'Pre-OS Boot: ROMMONkit',
    description: 'Pre-OS Boot: ROMMONkit',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542/004`
  },
  'T1542.005': {
    id: 'T1542.005',
    name: 'Pre-OS Boot: TFTP Boot',
    description: 'Pre-OS Boot: TFTP Boot',
    reference: `${MITRE_TECHNIQUE_SITE}/T1542/005`
  },
  T1543: {
    id: 'T1543',
    name: 'Create or Modify System Process',
    description: 'Create or Modify System Process',
    reference: `${MITRE_TECHNIQUE_SITE}/T1543`
  },
  'T1543.001': {
    id: 'T1543.001',
    name: 'Create or Modify System Process: Launch Agent',
    description: 'Create or Modify System Process: Launch Agent',
    reference: `${MITRE_TECHNIQUE_SITE}/T1543/001`
  },
  'T1543.002': {
    id: 'T1543.002',
    name: 'Create or Modify System Process: Systemd Service',
    description: 'Create or Modify System Process: Systemd Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1543/002`
  },
  'T1543.003': {
    id: 'T1543.003',
    name: 'Create or Modify System Process: Windows Service',
    description: 'Create or Modify System Process: Windows Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1543/003`
  },
  'T1543.004': {
    id: 'T1543.004',
    name: 'Create or Modify System Process: Launch Daemon',
    description: 'Create or Modify System Process: Launch Daemon',
    reference: `${MITRE_TECHNIQUE_SITE}/T1543/004`
  },
  T1546: {
    id: 'T1546',
    name: 'Event Triggered Execution',
    description: 'Event Triggered Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546`
  },
  'T1546.001': {
    id: 'T1546.001',
    name: 'Event Triggered Execution: Change Default File Association',
    description: 'Event Triggered Execution: Change Default File Association',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/001`
  },
  'T1546.002': {
    id: 'T1546.002',
    name: 'Event Triggered Execution: Screensaver',
    description: 'Event Triggered Execution: Screensaver',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/002`
  },
  'T1546.003': {
    id: 'T1546.003',
    name: 'Event Triggered Execution: Windows Management Instrumentation Event Subscription',
    description:
      'Event Triggered Execution: Windows Management Instrumentation Event Subscription',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/003`
  },
  'T1546.004': {
    id: 'T1546.004',
    name: 'Event Triggered Execution: Unix Shell Configuration Modification',
    description:
      'Event Triggered Execution: Unix Shell Configuration Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/004`
  },
  'T1546.005': {
    id: 'T1546.005',
    name: 'Event Triggered Execution: Trap',
    description: 'Event Triggered Execution: Trap',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/005`
  },
  'T1546.006': {
    id: 'T1546.006',
    name: 'Event Triggered Execution: LC_LOAD_DYLIB Addition',
    description: 'Event Triggered Execution: LC_LOAD_DYLIB Addition',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/006`
  },
  'T1546.007': {
    id: 'T1546.007',
    name: 'Event Triggered Execution: Netsh Helper DLL',
    description: 'Event Triggered Execution: Netsh Helper DLL',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/007`
  },
  'T1546.008': {
    id: 'T1546.008',
    name: 'Event Triggered Execution: Accessibility Features',
    description: 'Event Triggered Execution: Accessibility Features',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/008`
  },
  'T1546.009': {
    id: 'T1546.009',
    name: 'Event Triggered Execution: AppCert DLLs',
    description: 'Event Triggered Execution: AppCert DLLs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/009`
  },
  'T1546.010': {
    id: 'T1546.010',
    name: 'Event Triggered Execution: AppInit DLLs',
    description: 'Event Triggered Execution: AppInit DLLs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/010`
  },
  'T1546.011': {
    id: 'T1546.011',
    name: 'Event Triggered Execution: Application Shimming',
    description: 'Event Triggered Execution: Application Shimming',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/011`
  },
  'T1546.012': {
    id: 'T1546.012',
    name: 'Event Triggered Execution: Image File Execution Options Injection',
    description:
      'Event Triggered Execution: Image File Execution Options Injection',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/012`
  },
  'T1546.013': {
    id: 'T1546.013',
    name: 'Event Triggered Execution: PowerShell Profile',
    description: 'Event Triggered Execution: PowerShell Profile',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/013`
  },
  'T1546.014': {
    id: 'T1546.014',
    name: 'Event Triggered Execution: Emond',
    description: 'Event Triggered Execution: Emond',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/014`
  },
  'T1546.015': {
    id: 'T1546.015',
    name: 'Event Triggered Execution: Component Object Model Hijacking',
    description: 'Event Triggered Execution: Component Object Model Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1546/015`
  },
  T1547: {
    id: 'T1547',
    name: 'Boot or Logon Autostart Execution',
    description: 'Boot or Logon Autostart Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547`
  },
  'T1547.001': {
    id: 'T1547.001',
    name: 'Boot or Logon Autostart Execution: Registry Run Keys / Startup Folder',
    description:
      'Boot or Logon Autostart Execution: Registry Run Keys / Startup Folder',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/001`
  },
  'T1547.002': {
    id: 'T1547.002',
    name: 'Boot or Logon Autostart Execution: Authentication Package',
    description: 'Boot or Logon Autostart Execution: Authentication Package',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/002`
  },
  'T1547.003': {
    id: 'T1547.003',
    name: 'Boot or Logon Autostart Execution: Time Providers',
    description: 'Boot or Logon Autostart Execution: Time Providers',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/003`
  },
  'T1547.004': {
    id: 'T1547.004',
    name: 'Boot or Logon Autostart Execution: Winlogon Helper DLL',
    description: 'Boot or Logon Autostart Execution: Winlogon Helper DLL',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/004`
  },
  'T1547.005': {
    id: 'T1547.005',
    name: 'Boot or Logon Autostart Execution: Security Support Provider',
    description: 'Boot or Logon Autostart Execution: Security Support Provider',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/005`
  },
  'T1547.006': {
    id: 'T1547.006',
    name: 'Boot or Logon Autostart Execution: Kernel Modules and Extensions',
    description:
      'Boot or Logon Autostart Execution: Kernel Modules and Extensions',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/006`
  },
  'T1547.007': {
    id: 'T1547.007',
    name: 'Boot or Logon Autostart Execution: Re-opened Applications',
    description: 'Boot or Logon Autostart Execution: Re-opened Applications',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/007`
  },
  'T1547.008': {
    id: 'T1547.008',
    name: 'Boot or Logon Autostart Execution: LSASS Driver',
    description: 'Boot or Logon Autostart Execution: LSASS Driver',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/008`
  },
  'T1547.009': {
    id: 'T1547.009',
    name: 'Boot or Logon Autostart Execution: Shortcut Modification',
    description: 'Boot or Logon Autostart Execution: Shortcut Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/009`
  },
  'T1547.010': {
    id: 'T1547.010',
    name: 'Boot or Logon Autostart Execution: Port Monitors',
    description: 'Boot or Logon Autostart Execution: Port Monitors',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/010`
  },
  'T1547.011': {
    id: 'T1547.011',
    name: 'Boot or Logon Autostart Execution: Plist Modification',
    description: 'Boot or Logon Autostart Execution: Plist Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/011`
  },
  'T1547.012': {
    id: 'T1547.012',
    name: 'Boot or Logon Autostart Execution: Print Processors',
    description: 'Boot or Logon Autostart Execution: Print Processors',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/012`
  },
  'T1547.013': {
    id: 'T1547.013',
    name: 'Boot or Logon Autostart Execution: XDG Autostart Entries',
    description: 'Boot or Logon Autostart Execution: XDG Autostart Entries',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/013`
  },
  'T1547.014': {
    id: 'T1547.014',
    name: 'Boot or Logon Autostart Execution: Active Setup',
    description: 'Boot or Logon Autostart Execution: Active Setup',
    reference: `${MITRE_TECHNIQUE_SITE}/T1547/014`
  },
  T1548: {
    id: 'T1548',
    name: 'Abuse Elevation Control Mechanism',
    description: 'Abuse Elevation Control Mechanism',
    reference: `${MITRE_TECHNIQUE_SITE}/T1548`
  },
  'T1548.001': {
    id: 'T1548.001',
    name: 'Abuse Elevation Control Mechanism: Setuid and Setgid',
    description: 'Abuse Elevation Control Mechanism: Setuid and Setgid',
    reference: `${MITRE_TECHNIQUE_SITE}/T1548/001`
  },
  'T1548.002': {
    id: 'T1548.002',
    name: 'Abuse Elevation Control Mechanism: Bypass User Account Control',
    description:
      'Abuse Elevation Control Mechanism: Bypass User Account Control',
    reference: `${MITRE_TECHNIQUE_SITE}/T1548/002`
  },
  'T1548.003': {
    id: 'T1548.003',
    name: 'Abuse Elevation Control Mechanism: Sudo and Sudo Caching',
    description: 'Abuse Elevation Control Mechanism: Sudo and Sudo Caching',
    reference: `${MITRE_TECHNIQUE_SITE}/T1548/003`
  },
  'T1548.004': {
    id: 'T1548.004',
    name: 'Abuse Elevation Control Mechanism: Elevated Execution with Prompt',
    description:
      'Abuse Elevation Control Mechanism: Elevated Execution with Prompt',
    reference: `${MITRE_TECHNIQUE_SITE}/T1548/004`
  },
  T1550: {
    id: 'T1550',
    name: 'Use Alternate Authentication Material',
    description: 'Use Alternate Authentication Material',
    reference: `${MITRE_TECHNIQUE_SITE}/T1550`
  },
  'T1550.001': {
    id: 'T1550.001',
    name: 'Use Alternate Authentication Material: Application Access Token',
    description:
      'Use Alternate Authentication Material: Application Access Token',
    reference: `${MITRE_TECHNIQUE_SITE}/T1550/001`
  },
  'T1550.002': {
    id: 'T1550.002',
    name: 'Use Alternate Authentication Material: Pass the Hash',
    description: 'Use Alternate Authentication Material: Pass the Hash',
    reference: `${MITRE_TECHNIQUE_SITE}/T1550/002`
  },
  'T1550.003': {
    id: 'T1550.003',
    name: 'Use Alternate Authentication Material: Pass the Ticket',
    description: 'Use Alternate Authentication Material: Pass the Ticket',
    reference: `${MITRE_TECHNIQUE_SITE}/T1550/003`
  },
  'T1550.004': {
    id: 'T1550.004',
    name: 'Use Alternate Authentication Material: Web Session Cookie',
    description: 'Use Alternate Authentication Material: Web Session Cookie',
    reference: `${MITRE_TECHNIQUE_SITE}/T1550/004`
  },
  T1552: {
    id: 'T1552',
    name: 'Unsecured Credentials',
    description: 'Unsecured Credentials',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552`
  },
  'T1552.001': {
    id: 'T1552.001',
    name: 'Unsecured Credentials: Credentials In Files',
    description: 'Unsecured Credentials: Credentials In Files',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/001`
  },
  'T1552.002': {
    id: 'T1552.002',
    name: 'Unsecured Credentials: Credentials in Registry',
    description: 'Unsecured Credentials: Credentials in Registry',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/002`
  },
  'T1552.003': {
    id: 'T1552.003',
    name: 'Unsecured Credentials: Bash History',
    description: 'Unsecured Credentials: Bash History',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/003`
  },
  'T1552.004': {
    id: 'T1552.004',
    name: 'Unsecured Credentials: Private Keys',
    description: 'Unsecured Credentials: Private Keys',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/004`
  },
  'T1552.005': {
    id: 'T1552.005',
    name: 'Unsecured Credentials: Cloud Instance Metadata API',
    description: 'Unsecured Credentials: Cloud Instance Metadata API',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/005`
  },
  'T1552.006': {
    id: 'T1552.006',
    name: 'Unsecured Credentials: Group Policy Preferences',
    description: 'Unsecured Credentials: Group Policy Preferences',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/006`
  },
  'T1552.007': {
    id: 'T1552.007',
    name: 'Unsecured Credentials: Container API',
    description: 'Unsecured Credentials: Container API',
    reference: `${MITRE_TECHNIQUE_SITE}/T1552/007`
  },
  T1553: {
    id: 'T1553',
    name: 'Subvert Trust Controls',
    description: 'Subvert Trust Controls',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553`
  },
  'T1553.001': {
    id: 'T1553.001',
    name: 'Subvert Trust Controls: Gatekeeper Bypass',
    description: 'Subvert Trust Controls: Gatekeeper Bypass',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/001`
  },
  'T1553.002': {
    id: 'T1553.002',
    name: 'Subvert Trust Controls: Code Signing',
    description: 'Subvert Trust Controls: Code Signing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/002`
  },
  'T1553.003': {
    id: 'T1553.003',
    name: 'Subvert Trust Controls: SIP and Trust Provider Hijacking',
    description: 'Subvert Trust Controls: SIP and Trust Provider Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/003`
  },
  'T1553.004': {
    id: 'T1553.004',
    name: 'Subvert Trust Controls: Install Root Certificate',
    description: 'Subvert Trust Controls: Install Root Certificate',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/004`
  },
  'T1553.005': {
    id: 'T1553.005',
    name: 'Subvert Trust Controls: Mark-of-the-Web Bypass',
    description: 'Subvert Trust Controls: Mark-of-the-Web Bypass',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/005`
  },
  'T1553.006': {
    id: 'T1553.006',
    name: 'Subvert Trust Controls: Code Signing Policy Modification',
    description: 'Subvert Trust Controls: Code Signing Policy Modification',
    reference: `${MITRE_TECHNIQUE_SITE}/T1553/006`
  },
  T1554: {
    id: 'T1554',
    name: 'Compromise Client Software Binary',
    description: 'Compromise Client Software Binary',
    reference: `${MITRE_TECHNIQUE_SITE}/T1554`
  },
  T1555: {
    id: 'T1555',
    name: 'Credentials from Password Stores',
    description: 'Credentials from Password Stores',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555`
  },
  'T1555.001': {
    id: 'T1555.001',
    name: 'Credentials from Password Stores: Keychain',
    description: 'Credentials from Password Stores: Keychain',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555/001`
  },
  'T1555.002': {
    id: 'T1555.002',
    name: 'Credentials from Password Stores: Securityd Memory',
    description: 'Credentials from Password Stores: Securityd Memory',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555/002`
  },
  'T1555.003': {
    id: 'T1555.003',
    name: 'Credentials from Password Stores: Credentials from Web Browsers',
    description:
      'Credentials from Password Stores: Credentials from Web Browsers',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555/003`
  },
  'T1555.004': {
    id: 'T1555.004',
    name: 'Credentials from Password Stores: Windows Credential Manager',
    description: 'Credentials from Password Stores: Windows Credential Manager',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555/004`
  },
  'T1555.005': {
    id: 'T1555.005',
    name: 'Credentials from Password Stores: Password Managers',
    description: 'Credentials from Password Stores: Password Managers',
    reference: `${MITRE_TECHNIQUE_SITE}/T1555/005`
  },
  T1556: {
    id: 'T1556',
    name: 'Modify Authentication Process',
    description: 'Modify Authentication Process',
    reference: `${MITRE_TECHNIQUE_SITE}/T1556`
  },
  'T1556.001': {
    id: 'T1556.001',
    name: 'Modify Authentication Process: Domain Controller Authentication',
    description:
      'Modify Authentication Process: Domain Controller Authentication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1556/001`
  },
  'T1556.002': {
    id: 'T1556.002',
    name: 'Modify Authentication Process: Password Filter DLL',
    description: 'Modify Authentication Process: Password Filter DLL',
    reference: `${MITRE_TECHNIQUE_SITE}/T1556/002`
  },
  'T1556.003': {
    id: 'T1556.003',
    name: 'Modify Authentication Process: Pluggable Authentication Modules',
    description:
      'Modify Authentication Process: Pluggable Authentication Modules',
    reference: `${MITRE_TECHNIQUE_SITE}/T1556/003`
  },
  'T1556.004': {
    id: 'T1556.004',
    name: 'Modify Authentication Process: Network Device Authentication',
    description: 'Modify Authentication Process: Network Device Authentication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1556/004`
  },
  T1557: {
    id: 'T1557',
    name: 'Man-in-the-Middle',
    description: 'Man-in-the-Middle',
    reference: `${MITRE_TECHNIQUE_SITE}/T1557`
  },
  'T1557.001': {
    id: 'T1557.001',
    name: 'Man-in-the-Middle: LLMNR/NBT-NS Poisoning and SMB Relay',
    description: 'Man-in-the-Middle: LLMNR/NBT-NS Poisoning and SMB Relay',
    reference: `${MITRE_TECHNIQUE_SITE}/T1557/001`
  },
  'T1557.002': {
    id: 'T1557.002',
    name: 'Man-in-the-Middle: ARP Cache Poisoning',
    description: 'Man-in-the-Middle: ARP Cache Poisoning',
    reference: `${MITRE_TECHNIQUE_SITE}/T1557/002`
  },
  T1558: {
    id: 'T1558',
    name: 'Steal or Forge Kerberos Tickets',
    description: 'Steal or Forge Kerberos Tickets',
    reference: `${MITRE_TECHNIQUE_SITE}/T1558`
  },
  'T1558.001': {
    id: 'T1558.001',
    name: 'Steal or Forge Kerberos Tickets: Golden Ticket',
    description: 'Steal or Forge Kerberos Tickets: Golden Ticket',
    reference: `${MITRE_TECHNIQUE_SITE}/T1558/001`
  },
  'T1558.002': {
    id: 'T1558.002',
    name: 'Steal or Forge Kerberos Tickets: Silver Ticket',
    description: 'Steal or Forge Kerberos Tickets: Silver Ticket',
    reference: `${MITRE_TECHNIQUE_SITE}/T1558/002`
  },
  'T1558.003': {
    id: 'T1558.003',
    name: 'Steal or Forge Kerberos Tickets: Kerberoasting',
    description: 'Steal or Forge Kerberos Tickets: Kerberoasting',
    reference: `${MITRE_TECHNIQUE_SITE}/T1558/003`
  },
  'T1558.004': {
    id: 'T1558.004',
    name: 'Steal or Forge Kerberos Tickets: AS-REP Roasting',
    description: 'Steal or Forge Kerberos Tickets: AS-REP Roasting',
    reference: `${MITRE_TECHNIQUE_SITE}/T1558/004`
  },
  T1559: {
    id: 'T1559',
    name: 'Inter-Process Communication',
    description: 'Inter-Process Communication',
    reference: `${MITRE_TECHNIQUE_SITE}/T1559`
  },
  'T1559.001': {
    id: 'T1559.001',
    name: 'Inter-Process Communication: Component Object Model',
    description: 'Inter-Process Communication: Component Object Model',
    reference: `${MITRE_TECHNIQUE_SITE}/T1559/001`
  },
  'T1559.002': {
    id: 'T1559.002',
    name: 'Inter-Process Communication: Dynamic Data Exchange',
    description: 'Inter-Process Communication: Dynamic Data Exchange',
    reference: `${MITRE_TECHNIQUE_SITE}/T1559/002`
  },
  T1560: {
    id: 'T1560',
    name: 'Archive Collected Data',
    description: 'Archive Collected Data',
    reference: `${MITRE_TECHNIQUE_SITE}/T1560`
  },
  'T1560.001': {
    id: 'T1560.001',
    name: 'Archive Collected Data: Archive via Utility',
    description: 'Archive Collected Data: Archive via Utility',
    reference: `${MITRE_TECHNIQUE_SITE}/T1560/001`
  },
  'T1560.002': {
    id: 'T1560.002',
    name: 'Archive Collected Data: Archive via Library',
    description: 'Archive Collected Data: Archive via Library',
    reference: `${MITRE_TECHNIQUE_SITE}/T1560/002`
  },
  'T1560.003': {
    id: 'T1560.003',
    name: 'Archive Collected Data: Archive via Custom Method',
    description: 'Archive Collected Data: Archive via Custom Method',
    reference: `${MITRE_TECHNIQUE_SITE}/T1560/003`
  },
  T1561: {
    id: 'T1561',
    name: 'Disk Wipe',
    description: 'Disk Wipe',
    reference: `${MITRE_TECHNIQUE_SITE}/T1561`
  },
  'T1561.001': {
    id: 'T1561.001',
    name: 'Disk Wipe: Disk Content Wipe',
    description: 'Disk Wipe: Disk Content Wipe',
    reference: `${MITRE_TECHNIQUE_SITE}/T1561/001`
  },
  'T1561.002': {
    id: 'T1561.002',
    name: 'Disk Wipe: Disk Structure Wipe',
    description: 'Disk Wipe: Disk Structure Wipe',
    reference: `${MITRE_TECHNIQUE_SITE}/T1561/002`
  },
  T1562: {
    id: 'T1562',
    name: 'Impair Defenses',
    description: 'Impair Defenses',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562`
  },
  'T1562.001': {
    id: 'T1562.001',
    name: 'Impair Defenses: Disable or Modify Tools',
    description: 'Impair Defenses: Disable or Modify Tools',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/001`
  },
  'T1562.002': {
    id: 'T1562.002',
    name: 'Impair Defenses: Disable Windows Event Logging',
    description: 'Impair Defenses: Disable Windows Event Logging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/002`
  },
  'T1562.003': {
    id: 'T1562.003',
    name: 'Impair Defenses: Impair Command History Logging',
    description: 'Impair Defenses: Impair Command History Logging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/003`
  },
  'T1562.004': {
    id: 'T1562.004',
    name: 'Impair Defenses: Disable or Modify System Firewall',
    description: 'Impair Defenses: Disable or Modify System Firewall',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/004`
  },
  'T1562.006': {
    id: 'T1562.006',
    name: 'Impair Defenses: Indicator Blocking',
    description: 'Impair Defenses: Indicator Blocking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/006`
  },
  'T1562.007': {
    id: 'T1562.007',
    name: 'Impair Defenses: Disable or Modify Cloud Firewall',
    description: 'Impair Defenses: Disable or Modify Cloud Firewall',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/007`
  },
  'T1562.008': {
    id: 'T1562.008',
    name: 'Impair Defenses: Disable Cloud Logs',
    description: 'Impair Defenses: Disable Cloud Logs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1562/008`
  },
  T1563: {
    id: 'T1563',
    name: 'Remote Service Session Hijacking',
    description: 'Remote Service Session Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1563`
  },
  'T1563.001': {
    id: 'T1563.001',
    name: 'Remote Service Session Hijacking: SSH Hijacking',
    description: 'Remote Service Session Hijacking: SSH Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1563/001`
  },
  'T1563.002': {
    id: 'T1563.002',
    name: 'Remote Service Session Hijacking: RDP Hijacking',
    description: 'Remote Service Session Hijacking: RDP Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1563/002`
  },
  T1564: {
    id: 'T1564',
    name: 'Hide Artifacts',
    description: 'Hide Artifacts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564`
  },
  'T1564.001': {
    id: 'T1564.001',
    name: 'Hide Artifacts: Hidden Files and Directories',
    description: 'Hide Artifacts: Hidden Files and Directories',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/001`
  },
  'T1564.002': {
    id: 'T1564.002',
    name: 'Hide Artifacts: Hidden Users',
    description: 'Hide Artifacts: Hidden Users',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/002`
  },
  'T1564.003': {
    id: 'T1564.003',
    name: 'Hide Artifacts: Hidden Window',
    description: 'Hide Artifacts: Hidden Window',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/003`
  },
  'T1564.004': {
    id: 'T1564.004',
    name: 'Hide Artifacts: NTFS File Attributes',
    description: 'Hide Artifacts: NTFS File Attributes',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/004`
  },
  'T1564.005': {
    id: 'T1564.005',
    name: 'Hide Artifacts: Hidden File System',
    description: 'Hide Artifacts: Hidden File System',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/005`
  },
  'T1564.006': {
    id: 'T1564.006',
    name: 'Hide Artifacts: Run Virtual Instance',
    description: 'Hide Artifacts: Run Virtual Instance',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/006`
  },
  'T1564.007': {
    id: 'T1564.007',
    name: 'Hide Artifacts: VBA Stomping',
    description: 'Hide Artifacts: VBA Stomping',
    reference: `${MITRE_TECHNIQUE_SITE}/T1564/007`
  },
  T1565: {
    id: 'T1565',
    name: 'Data Manipulation',
    description: 'Data Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1565`
  },
  'T1565.001': {
    id: 'T1565.001',
    name: 'Data Manipulation: Stored Data Manipulation',
    description: 'Data Manipulation: Stored Data Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1565/001`
  },
  'T1565.002': {
    id: 'T1565.002',
    name: 'Data Manipulation: Transmitted Data Manipulation',
    description: 'Data Manipulation: Transmitted Data Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1565/002`
  },
  'T1565.003': {
    id: 'T1565.003',
    name: 'Data Manipulation: Runtime Data Manipulation',
    description: 'Data Manipulation: Runtime Data Manipulation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1565/003`
  },
  T1566: {
    id: 'T1566',
    name: 'Phishing',
    description: 'Phishing',
    reference: `${MITRE_TECHNIQUE_SITE}/T1566`
  },
  'T1566.001': {
    id: 'T1566.001',
    name: 'Phishing: Spearphishing Attachment',
    description: 'Phishing: Spearphishing Attachment',
    reference: `${MITRE_TECHNIQUE_SITE}/T1566/001`
  },
  'T1566.002': {
    id: 'T1566.002',
    name: 'Phishing: Spearphishing Link',
    description: 'Phishing: Spearphishing Link',
    reference: `${MITRE_TECHNIQUE_SITE}/T1566/002`
  },
  'T1566.003': {
    id: 'T1566.003',
    name: 'Phishing: Spearphishing via Service',
    description: 'Phishing: Spearphishing via Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1566/003`
  },
  T1567: {
    id: 'T1567',
    name: 'Exfiltration Over Web Service',
    description: 'Exfiltration Over Web Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1567`
  },
  'T1567.001': {
    id: 'T1567.001',
    name: 'Exfiltration Over Web Service: Exfiltration to Code Repository',
    description:
      'Exfiltration Over Web Service: Exfiltration to Code Repository',
    reference: `${MITRE_TECHNIQUE_SITE}/T1567/001`
  },
  'T1567.002': {
    id: 'T1567.002',
    name: 'Exfiltration Over Web Service: Exfiltration to Cloud Storage',
    description: 'Exfiltration Over Web Service: Exfiltration to Cloud Storage',
    reference: `${MITRE_TECHNIQUE_SITE}/T1567/002`
  },
  T1568: {
    id: 'T1568',
    name: 'Dynamic Resolution',
    description: 'Dynamic Resolution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1568`
  },
  'T1568.001': {
    id: 'T1568.001',
    name: 'Dynamic Resolution: Fast Flux DNS',
    description: 'Dynamic Resolution: Fast Flux DNS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1568/001`
  },
  'T1568.002': {
    id: 'T1568.002',
    name: 'Dynamic Resolution: Domain Generation Algorithms',
    description: 'Dynamic Resolution: Domain Generation Algorithms',
    reference: `${MITRE_TECHNIQUE_SITE}/T1568/002`
  },
  'T1568.003': {
    id: 'T1568.003',
    name: 'Dynamic Resolution: DNS Calculation',
    description: 'Dynamic Resolution: DNS Calculation',
    reference: `${MITRE_TECHNIQUE_SITE}/T1568/003`
  },
  T1569: {
    id: 'T1569',
    name: 'System Services',
    description: 'System Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1569`
  },
  'T1569.001': {
    id: 'T1569.001',
    name: 'System Services: Launchctl',
    description: 'System Services: Launchctl',
    reference: `${MITRE_TECHNIQUE_SITE}/T1569/001`
  },
  'T1569.002': {
    id: 'T1569.002',
    name: 'System Services: Service Execution',
    description: 'System Services: Service Execution',
    reference: `${MITRE_TECHNIQUE_SITE}/T1569/002`
  },
  T1570: {
    id: 'T1570',
    name: 'Lateral Tool Transfer',
    description: 'Lateral Tool Transfer',
    reference: `${MITRE_TECHNIQUE_SITE}/T1570`
  },
  T1571: {
    id: 'T1571',
    name: 'Non-Standard Port',
    description: 'Non-Standard Port',
    reference: `${MITRE_TECHNIQUE_SITE}/T1571`
  },
  T1572: {
    id: 'T1572',
    name: 'Protocol Tunneling',
    description: 'Protocol Tunneling',
    reference: `${MITRE_TECHNIQUE_SITE}/T1572`
  },
  T1573: {
    id: 'T1573',
    name: 'Encrypted Channel',
    description: 'Encrypted Channel',
    reference: `${MITRE_TECHNIQUE_SITE}/T1573`
  },
  'T1573.001': {
    id: 'T1573.001',
    name: 'Encrypted Channel: Symmetric Cryptography',
    description: 'Encrypted Channel: Symmetric Cryptography',
    reference: `${MITRE_TECHNIQUE_SITE}/T1573/001`
  },
  'T1573.002': {
    id: 'T1573.002',
    name: 'Encrypted Channel: Asymmetric Cryptography',
    description: 'Encrypted Channel: Asymmetric Cryptography',
    reference: `${MITRE_TECHNIQUE_SITE}/T1573/002`
  },
  T1574: {
    id: 'T1574',
    name: 'Hijack Execution Flow',
    description: 'Hijack Execution Flow',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574`
  },
  'T1574.001': {
    id: 'T1574.001',
    name: 'Hijack Execution Flow: DLL Search Order Hijacking',
    description: 'Hijack Execution Flow: DLL Search Order Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/001`
  },
  'T1574.002': {
    id: 'T1574.002',
    name: 'Hijack Execution Flow: DLL Side-Loading',
    description: 'Hijack Execution Flow: DLL Side-Loading',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/002`
  },
  'T1574.004': {
    id: 'T1574.004',
    name: 'Hijack Execution Flow: Dylib Hijacking',
    description: 'Hijack Execution Flow: Dylib Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/004`
  },
  'T1574.005': {
    id: 'T1574.005',
    name: 'Hijack Execution Flow: Executable Installer File Permissions Weakness',
    description:
      'Hijack Execution Flow: Executable Installer File Permissions Weakness',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/005`
  },
  'T1574.006': {
    id: 'T1574.006',
    name: 'Hijack Execution Flow: Dynamic Linker Hijacking',
    description: 'Hijack Execution Flow: Dynamic Linker Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/006`
  },
  'T1574.007': {
    id: 'T1574.007',
    name: 'Hijack Execution Flow: Path Interception by PATH Environment Variable',
    description:
      'Hijack Execution Flow: Path Interception by PATH Environment Variable',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/007`
  },
  'T1574.008': {
    id: 'T1574.008',
    name: 'Hijack Execution Flow: Path Interception by Search Order Hijacking',
    description:
      'Hijack Execution Flow: Path Interception by Search Order Hijacking',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/008`
  },
  'T1574.009': {
    id: 'T1574.009',
    name: 'Hijack Execution Flow: Path Interception by Unquoted Path',
    description: 'Hijack Execution Flow: Path Interception by Unquoted Path',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/009`
  },
  'T1574.010': {
    id: 'T1574.010',
    name: 'Hijack Execution Flow: Services File Permissions Weakness',
    description: 'Hijack Execution Flow: Services File Permissions Weakness',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/010`
  },
  'T1574.011': {
    id: 'T1574.011',
    name: 'Hijack Execution Flow: Services Registry Permissions Weakness',
    description:
      'Hijack Execution Flow: Services Registry Permissions Weakness',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/011`
  },
  'T1574.012': {
    id: 'T1574.012',
    name: 'Hijack Execution Flow: COR_PROFILER',
    description: 'Hijack Execution Flow: COR_PROFILER',
    reference: `${MITRE_TECHNIQUE_SITE}/T1574/012`
  },
  T1578: {
    id: 'T1578',
    name: 'Modify Cloud Compute Infrastructure',
    description: 'Modify Cloud Compute Infrastructure',
    reference: `${MITRE_TECHNIQUE_SITE}/T1578`
  },
  'T1578.001': {
    id: 'T1578.001',
    name: 'Modify Cloud Compute Infrastructure: Create Snapshot',
    description: 'Modify Cloud Compute Infrastructure: Create Snapshot',
    reference: `${MITRE_TECHNIQUE_SITE}/T1578/001`
  },
  'T1578.002': {
    id: 'T1578.002',
    name: 'Modify Cloud Compute Infrastructure: Create Cloud Instance',
    description: 'Modify Cloud Compute Infrastructure: Create Cloud Instance',
    reference: `${MITRE_TECHNIQUE_SITE}/T1578/002`
  },
  'T1578.003': {
    id: 'T1578.003',
    name: 'Modify Cloud Compute Infrastructure: Delete Cloud Instance',
    description: 'Modify Cloud Compute Infrastructure: Delete Cloud Instance',
    reference: `${MITRE_TECHNIQUE_SITE}/T1578/003`
  },
  'T1578.004': {
    id: 'T1578.004',
    name: 'Modify Cloud Compute Infrastructure: Revert Cloud Instance',
    description: 'Modify Cloud Compute Infrastructure: Revert Cloud Instance',
    reference: `${MITRE_TECHNIQUE_SITE}/T1578/004`
  },
  T1580: {
    id: 'T1580',
    name: 'Cloud Infrastructure Discovery',
    description: 'Cloud Infrastructure Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1580`
  },
  T1583: {
    id: 'T1583',
    name: 'Acquire Infrastructure',
    description: 'Acquire Infrastructure',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583`
  },
  'T1583.001': {
    id: 'T1583.001',
    name: 'Acquire Infrastructure: Domains',
    description: 'Acquire Infrastructure: Domains',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/001`
  },
  'T1583.002': {
    id: 'T1583.002',
    name: 'Acquire Infrastructure: DNS Server',
    description: 'Acquire Infrastructure: DNS Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/002`
  },
  'T1583.003': {
    id: 'T1583.003',
    name: 'Acquire Infrastructure: Virtual Private Server',
    description: 'Acquire Infrastructure: Virtual Private Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/003`
  },
  'T1583.004': {
    id: 'T1583.004',
    name: 'Acquire Infrastructure: Server',
    description: 'Acquire Infrastructure: Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/004`
  },
  'T1583.005': {
    id: 'T1583.005',
    name: 'Acquire Infrastructure: Botnet',
    description: 'Acquire Infrastructure: Botnet',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/005`
  },
  'T1583.006': {
    id: 'T1583.006',
    name: 'Acquire Infrastructure: Web Services',
    description: 'Acquire Infrastructure: Web Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1583/006`
  },
  T1584: {
    id: 'T1584',
    name: 'Compromise Infrastructure',
    description: 'Compromise Infrastructure',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584`
  },
  'T1584.001': {
    id: 'T1584.001',
    name: 'Compromise Infrastructure: Domains',
    description: 'Compromise Infrastructure: Domains',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/001`
  },
  'T1584.002': {
    id: 'T1584.002',
    name: 'Compromise Infrastructure: DNS Server',
    description: 'Compromise Infrastructure: DNS Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/002`
  },
  'T1584.003': {
    id: 'T1584.003',
    name: 'Compromise Infrastructure: Virtual Private Server',
    description: 'Compromise Infrastructure: Virtual Private Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/003`
  },
  'T1584.004': {
    id: 'T1584.004',
    name: 'Compromise Infrastructure: Server',
    description: 'Compromise Infrastructure: Server',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/004`
  },
  'T1584.005': {
    id: 'T1584.005',
    name: 'Compromise Infrastructure: Botnet',
    description: 'Compromise Infrastructure: Botnet',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/005`
  },
  'T1584.006': {
    id: 'T1584.006',
    name: 'Compromise Infrastructure: Web Services',
    description: 'Compromise Infrastructure: Web Services',
    reference: `${MITRE_TECHNIQUE_SITE}/T1584/006`
  },
  T1585: {
    id: 'T1585',
    name: 'Establish Accounts',
    description: 'Establish Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1585`
  },
  'T1585.001': {
    id: 'T1585.001',
    name: 'Establish Accounts: Social Media Accounts',
    description: 'Establish Accounts: Social Media Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1585/001`
  },
  'T1585.002': {
    id: 'T1585.002',
    name: 'Establish Accounts: Email Accounts',
    description: 'Establish Accounts: Email Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1585/002`
  },
  T1586: {
    id: 'T1586',
    name: 'Compromise Accounts',
    description: 'Compromise Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1586`
  },
  'T1586.001': {
    id: 'T1586.001',
    name: 'Compromise Accounts: Social Media Accounts',
    description: 'Compromise Accounts: Social Media Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1586/001`
  },
  'T1586.002': {
    id: 'T1586.002',
    name: 'Compromise Accounts: Email Accounts',
    description: 'Compromise Accounts: Email Accounts',
    reference: `${MITRE_TECHNIQUE_SITE}/T1586/002`
  },
  T1587: {
    id: 'T1587',
    name: 'Develop Capabilities',
    description: 'Develop Capabilities',
    reference: `${MITRE_TECHNIQUE_SITE}/T1587`
  },
  'T1587.001': {
    id: 'T1587.001',
    name: 'Develop Capabilities: Malware',
    description: 'Develop Capabilities: Malware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1587/001`
  },
  'T1587.002': {
    id: 'T1587.002',
    name: 'Develop Capabilities: Code Signing Certificates',
    description: 'Develop Capabilities: Code Signing Certificates',
    reference: `${MITRE_TECHNIQUE_SITE}/T1587/002`
  },
  'T1587.003': {
    id: 'T1587.003',
    name: 'Develop Capabilities: Digital Certificates',
    description: 'Develop Capabilities: Digital Certificates',
    reference: `${MITRE_TECHNIQUE_SITE}/T1587/003`
  },
  'T1587.004': {
    id: 'T1587.004',
    name: 'Develop Capabilities: Exploits',
    description: 'Develop Capabilities: Exploits',
    reference: `${MITRE_TECHNIQUE_SITE}/T1587/004`
  },
  T1588: {
    id: 'T1588',
    name: 'Obtain Capabilities',
    description: 'Obtain Capabilities',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588`
  },
  'T1588.001': {
    id: 'T1588.001',
    name: 'Obtain Capabilities: Malware',
    description: 'Obtain Capabilities: Malware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/001`
  },
  'T1588.002': {
    id: 'T1588.002',
    name: 'Obtain Capabilities: Tool',
    description: 'Obtain Capabilities: Tool',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/002`
  },
  'T1588.003': {
    id: 'T1588.003',
    name: 'Obtain Capabilities: Code Signing Certificates',
    description: 'Obtain Capabilities: Code Signing Certificates',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/003`
  },
  'T1588.004': {
    id: 'T1588.004',
    name: 'Obtain Capabilities: Digital Certificates',
    description: 'Obtain Capabilities: Digital Certificates',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/004`
  },
  'T1588.005': {
    id: 'T1588.005',
    name: 'Obtain Capabilities: Exploits',
    description: 'Obtain Capabilities: Exploits',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/005`
  },
  'T1588.006': {
    id: 'T1588.006',
    name: 'Obtain Capabilities: Vulnerabilities',
    description: 'Obtain Capabilities: Vulnerabilities',
    reference: `${MITRE_TECHNIQUE_SITE}/T1588/006`
  },
  T1589: {
    id: 'T1589',
    name: 'Gather Victim Identity Information',
    description: 'Gather Victim Identity Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1589`
  },
  'T1589.001': {
    id: 'T1589.001',
    name: 'Gather Victim Identity Information: Credentials',
    description: 'Gather Victim Identity Information: Credentials',
    reference: `${MITRE_TECHNIQUE_SITE}/T1589/001`
  },
  'T1589.002': {
    id: 'T1589.002',
    name: 'Gather Victim Identity Information: Email Addresses',
    description: 'Gather Victim Identity Information: Email Addresses',
    reference: `${MITRE_TECHNIQUE_SITE}/T1589/002`
  },
  'T1589.003': {
    id: 'T1589.003',
    name: 'Gather Victim Identity Information: Employee Names',
    description: 'Gather Victim Identity Information: Employee Names',
    reference: `${MITRE_TECHNIQUE_SITE}/T1589/003`
  },
  T1590: {
    id: 'T1590',
    name: 'Gather Victim Network Information',
    description: 'Gather Victim Network Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590`
  },
  'T1590.001': {
    id: 'T1590.001',
    name: 'Gather Victim Network Information: Domain Properties',
    description: 'Gather Victim Network Information: Domain Properties',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/001`
  },
  'T1590.002': {
    id: 'T1590.002',
    name: 'Gather Victim Network Information: DNS',
    description: 'Gather Victim Network Information: DNS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/002`
  },
  'T1590.003': {
    id: 'T1590.003',
    name: 'Gather Victim Network Information: Network Trust Dependencies',
    description:
      'Gather Victim Network Information: Network Trust Dependencies',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/003`
  },
  'T1590.004': {
    id: 'T1590.004',
    name: 'Gather Victim Network Information: Network Topology',
    description: 'Gather Victim Network Information: Network Topology',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/004`
  },
  'T1590.005': {
    id: 'T1590.005',
    name: 'Gather Victim Network Information: IP Addresses',
    description: 'Gather Victim Network Information: IP Addresses',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/005`
  },
  'T1590.006': {
    id: 'T1590.006',
    name: 'Gather Victim Network Information: Network Security Appliances',
    description:
      'Gather Victim Network Information: Network Security Appliances',
    reference: `${MITRE_TECHNIQUE_SITE}/T1590/006`
  },
  T1591: {
    id: 'T1591',
    name: 'Gather Victim Org Information',
    description: 'Gather Victim Org Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1591`
  },
  'T1591.001': {
    id: 'T1591.001',
    name: 'Gather Victim Org Information: Determine Physical Locations',
    description: 'Gather Victim Org Information: Determine Physical Locations',
    reference: `${MITRE_TECHNIQUE_SITE}/T1591/001`
  },
  'T1591.002': {
    id: 'T1591.002',
    name: 'Gather Victim Org Information: Business Relationships',
    description: 'Gather Victim Org Information: Business Relationships',
    reference: `${MITRE_TECHNIQUE_SITE}/T1591/002`
  },
  'T1591.003': {
    id: 'T1591.003',
    name: 'Gather Victim Org Information: Identify Business Tempo',
    description: 'Gather Victim Org Information: Identify Business Tempo',
    reference: `${MITRE_TECHNIQUE_SITE}/T1591/003`
  },
  'T1591.004': {
    id: 'T1591.004',
    name: 'Gather Victim Org Information: Identify Roles',
    description: 'Gather Victim Org Information: Identify Roles',
    reference: `${MITRE_TECHNIQUE_SITE}/T1591/004`
  },
  T1592: {
    id: 'T1592',
    name: 'Gather Victim Host Information',
    description: 'Gather Victim Host Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1592`
  },
  'T1592.001': {
    id: 'T1592.001',
    name: 'Gather Victim Host Information: Hardware',
    description: 'Gather Victim Host Information: Hardware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1592/001`
  },
  'T1592.002': {
    id: 'T1592.002',
    name: 'Gather Victim Host Information: Software',
    description: 'Gather Victim Host Information: Software',
    reference: `${MITRE_TECHNIQUE_SITE}/T1592/002`
  },
  'T1592.003': {
    id: 'T1592.003',
    name: 'Gather Victim Host Information: Firmware',
    description: 'Gather Victim Host Information: Firmware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1592/003`
  },
  'T1592.004': {
    id: 'T1592.004',
    name: 'Gather Victim Host Information: Client Configurations',
    description: 'Gather Victim Host Information: Client Configurations',
    reference: `${MITRE_TECHNIQUE_SITE}/T1592/004`
  },
  T1593: {
    id: 'T1593',
    name: 'Search Open Websites/Domains',
    description: 'Search Open Websites/Domains',
    reference: `${MITRE_TECHNIQUE_SITE}/T1593`
  },
  'T1593.001': {
    id: 'T1593.001',
    name: 'Search Open Websites/Domains: Social Media',
    description: 'Search Open Websites/Domains: Social Media',
    reference: `${MITRE_TECHNIQUE_SITE}/T1593/001`
  },
  'T1593.002': {
    id: 'T1593.002',
    name: 'Search Open Websites/Domains: Search Engines',
    description: 'Search Open Websites/Domains: Search Engines',
    reference: `${MITRE_TECHNIQUE_SITE}/T1593/002`
  },
  T1594: {
    id: 'T1594',
    name: 'Search Victim-Owned Websites',
    description: 'Search Victim-Owned Websites',
    reference: `${MITRE_TECHNIQUE_SITE}/T1594`
  },
  T1595: {
    id: 'T1595',
    name: 'Active Scanning',
    description: 'Active Scanning',
    reference: `${MITRE_TECHNIQUE_SITE}/T1595`
  },
  'T1595.001': {
    id: 'T1595.001',
    name: 'Active Scanning: Scanning IP Blocks',
    description: 'Active Scanning: Scanning IP Blocks',
    reference: `${MITRE_TECHNIQUE_SITE}/T1595/001`
  },
  'T1595.002': {
    id: 'T1595.002',
    name: 'Active Scanning: Vulnerability Scanning',
    description: 'Active Scanning: Vulnerability Scanning',
    reference: `${MITRE_TECHNIQUE_SITE}/T1595/002`
  },
  T1596: {
    id: 'T1596',
    name: 'Search Open Technical Databases',
    description: 'Search Open Technical Databases',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596`
  },
  'T1596.001': {
    id: 'T1596.001',
    name: 'Search Open Technical Databases: DNS/Passive DNS',
    description: 'Search Open Technical Databases: DNS/Passive DNS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596/001`
  },
  'T1596.002': {
    id: 'T1596.002',
    name: 'Search Open Technical Databases: WHOIS',
    description: 'Search Open Technical Databases: WHOIS',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596/002`
  },
  'T1596.003': {
    id: 'T1596.003',
    name: 'Search Open Technical Databases: Digital Certificates',
    description: 'Search Open Technical Databases: Digital Certificates',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596/003`
  },
  'T1596.004': {
    id: 'T1596.004',
    name: 'Search Open Technical Databases: CDNs',
    description: 'Search Open Technical Databases: CDNs',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596/004`
  },
  'T1596.005': {
    id: 'T1596.005',
    name: 'Search Open Technical Databases: Scan Databases',
    description: 'Search Open Technical Databases: Scan Databases',
    reference: `${MITRE_TECHNIQUE_SITE}/T1596/005`
  },
  T1597: {
    id: 'T1597',
    name: 'Search Closed Sources',
    description: 'Search Closed Sources',
    reference: `${MITRE_TECHNIQUE_SITE}/T1597`
  },
  'T1597.001': {
    id: 'T1597.001',
    name: 'Search Closed Sources: Threat Intel Vendors',
    description: 'Search Closed Sources: Threat Intel Vendors',
    reference: `${MITRE_TECHNIQUE_SITE}/T1597/001`
  },
  'T1597.002': {
    id: 'T1597.002',
    name: 'Search Closed Sources: Purchase Technical Data',
    description: 'Search Closed Sources: Purchase Technical Data',
    reference: `${MITRE_TECHNIQUE_SITE}/T1597/002`
  },
  T1598: {
    id: 'T1598',
    name: 'Phishing for Information',
    description: 'Phishing for Information',
    reference: `${MITRE_TECHNIQUE_SITE}/T1598`
  },
  'T1598.001': {
    id: 'T1598.001',
    name: 'Phishing for Information: Spearphishing Service',
    description: 'Phishing for Information: Spearphishing Service',
    reference: `${MITRE_TECHNIQUE_SITE}/T1598/001`
  },
  'T1598.002': {
    id: 'T1598.002',
    name: 'Phishing for Information: Spearphishing Attachment',
    description: 'Phishing for Information: Spearphishing Attachment',
    reference: `${MITRE_TECHNIQUE_SITE}/T1598/002`
  },
  'T1598.003': {
    id: 'T1598.003',
    name: 'Phishing for Information: Spearphishing Link',
    description: 'Phishing for Information: Spearphishing Link',
    reference: `${MITRE_TECHNIQUE_SITE}/T1598/003`
  },
  T1599: {
    id: 'T1599',
    name: 'Network Boundary Bridging',
    description: 'Network Boundary Bridging',
    reference: `${MITRE_TECHNIQUE_SITE}/T1599`
  },
  'T1599.001': {
    id: 'T1599.001',
    name: 'Network Boundary Bridging: Network Address Translation Traversal',
    description:
      'Network Boundary Bridging: Network Address Translation Traversal',
    reference: `${MITRE_TECHNIQUE_SITE}/T1599/001`
  },
  T1600: {
    id: 'T1600',
    name: 'Weaken Encryption',
    description: 'Weaken Encryption',
    reference: `${MITRE_TECHNIQUE_SITE}/T1600`
  },
  'T1600.001': {
    id: 'T1600.001',
    name: 'Weaken Encryption: Reduce Key Space',
    description: 'Weaken Encryption: Reduce Key Space',
    reference: `${MITRE_TECHNIQUE_SITE}/T1600/001`
  },
  'T1600.002': {
    id: 'T1600.002',
    name: 'Weaken Encryption: Disable Crypto Hardware',
    description: 'Weaken Encryption: Disable Crypto Hardware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1600/002`
  },
  T1601: {
    id: 'T1601',
    name: 'Modify System Image',
    description: 'Modify System Image',
    reference: `${MITRE_TECHNIQUE_SITE}/T1601`
  },
  'T1601.001': {
    id: 'T1601.001',
    name: 'Modify System Image: Patch System Image',
    description: 'Modify System Image: Patch System Image',
    reference: `${MITRE_TECHNIQUE_SITE}/T1601/001`
  },
  'T1601.002': {
    id: 'T1601.002',
    name: 'Modify System Image: Downgrade System Image',
    description: 'Modify System Image: Downgrade System Image',
    reference: `${MITRE_TECHNIQUE_SITE}/T1601/002`
  },
  T1602: {
    id: 'T1602',
    name: 'Data from Configuration Repository',
    description: 'Data from Configuration Repository',
    reference: `${MITRE_TECHNIQUE_SITE}/T1602`
  },
  'T1602.001': {
    id: 'T1602.001',
    name: 'Data from Configuration Repository: SNMP (MIB Dump)',
    description: 'Data from Configuration Repository: SNMP (MIB Dump)',
    reference: `${MITRE_TECHNIQUE_SITE}/T1602/001`
  },
  'T1602.002': {
    id: 'T1602.002',
    name: 'Data from Configuration Repository: Network Device Configuration Dump',
    description:
      'Data from Configuration Repository: Network Device Configuration Dump',
    reference: `${MITRE_TECHNIQUE_SITE}/T1602/002`
  },
  T1606: {
    id: 'T1606',
    name: 'Forge Web Credentials',
    description: 'Forge Web Credentials',
    reference: `${MITRE_TECHNIQUE_SITE}/T1606`
  },
  'T1606.001': {
    id: 'T1606.001',
    name: 'Forge Web Credentials: Web Cookies',
    description: 'Forge Web Credentials: Web Cookies',
    reference: `${MITRE_TECHNIQUE_SITE}/T1606/001`
  },
  'T1606.002': {
    id: 'T1606.002',
    name: 'Forge Web Credentials: SAML Tokens',
    description: 'Forge Web Credentials: SAML Tokens',
    reference: `${MITRE_TECHNIQUE_SITE}/T1606/002`
  },
  T1608: {
    id: 'T1608',
    name: 'Stage Capabilities',
    description: 'Stage Capabilities',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608`
  },
  'T1608.001': {
    id: 'T1608.001',
    name: 'Stage Capabilities: Upload Malware',
    description: 'Stage Capabilities: Upload Malware',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608/001`
  },
  'T1608.002': {
    id: 'T1608.002',
    name: 'Stage Capabilities: Upload Tool',
    description: 'Stage Capabilities: Upload Tool',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608/002`
  },
  'T1608.003': {
    id: 'T1608.003',
    name: 'Stage Capabilities: Install Digital Certificate',
    description: 'Stage Capabilities: Install Digital Certificate',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608/003`
  },
  'T1608.004': {
    id: 'T1608.004',
    name: 'Stage Capabilities: Drive-by Target',
    description: 'Stage Capabilities: Drive-by Target',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608/004`
  },
  'T1608.005': {
    id: 'T1608.005',
    name: 'Stage Capabilities: Link Target',
    description: 'Stage Capabilities: Link Target',
    reference: `${MITRE_TECHNIQUE_SITE}/T1608/005`
  },
  T1609: {
    id: 'T1609',
    name: 'Container Administration Command',
    description: 'Container Administration Command',
    reference: `${MITRE_TECHNIQUE_SITE}/T1609`
  },
  T1610: {
    id: 'T1610',
    name: 'Deploy Container',
    description: 'Deploy Container',
    reference: `${MITRE_TECHNIQUE_SITE}/T1610`
  },
  T1611: {
    id: 'T1611',
    name: 'Escape to Host',
    description: 'Escape to Host',
    reference: `${MITRE_TECHNIQUE_SITE}/T1611`
  },
  T1612: {
    id: 'T1612',
    name: 'Build Image on Host',
    description: 'Build Image on Host',
    reference: `${MITRE_TECHNIQUE_SITE}/T1612`
  },
  T1613: {
    id: 'T1613',
    name: 'Container and Resource Discovery',
    description: 'Container and Resource Discovery',
    reference: `${MITRE_TECHNIQUE_SITE}/T1613`
  },
  T1614: {
    id: 'T1614',
    name: 'System Location Discover',
    description: 'System Location Discover',
    reference: `${MITRE_TECHNIQUE_SITE}/T1614`
  }
}
