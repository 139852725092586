import { Box, Stack, Typography } from '@mui/material'
import React, { FC, ReactChild, useMemo } from 'react'
import { Card } from '@components'
import { SurfaceProps } from './Surface'
import { numberFormat } from '@utils/misc'
import { Skeleton } from '@mui/material'

interface Props<T = number | string> extends SurfaceProps {
  heading?: string
  loading?: boolean
  value: T
  unit: string
  description: string
  color?: SurfaceProps['color']
  isGood?: boolean
  isBad?: boolean
  isOfConcern?: boolean
  actions?: ReactChild | null | false
  headingButton?: ReactChild | Element | null | false
  className?: string
  setBgColor?: boolean
}

const HeroStat: FC<Props> = ({
  value,
  unit,
  description,
  children,
  className,
  loading = false,
  isGood = false,
  isBad = false,
  isOfConcern = false,
  setBgColor = false,
  ...rest
}) => {
  const color = useMemo(() => {
    if (isGood) return 'success'
    if (isOfConcern) return 'warning'
    if (isBad) return 'error'
    return 'default'
  }, [isGood, isOfConcern, isBad])

  return (
    <Card {...rest}>
      <Stack sx={{ height: '100%' }}>
        <Box p={2}>
          <Typography
            variant="h1"
            sx={{
              color: `${color}.main`
            }}
          >
            {loading ? (
              <Skeleton height="1.167em" width="4em" />
            ) : !isNaN(value as number) ? (
              numberFormat(value as number)
            ) : (
              (value as string)
            )}
            {!loading && (
              <sup style={{ fontSize: '40%', verticalAlign: 'baseline' }}>
                {' '}
                {unit}
              </sup>
            )}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {description}
          </Typography>
        </Box>
        {children}
      </Stack>
    </Card>
  )
}

export default HeroStat
