import {
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'

import { Button } from 'gatsby-material-ui-components'
import React from 'react'
import { BsLightning } from 'react-icons/bs'
import {
  GridFilterModel,
  GridRowId,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro'
import { makeStyles } from '@mui/styles'

export type TBulkActions = {
  text: string
  icon: JSX.Element
  action: (selection: string[]) => Promise<void>
  autoRefresh?: boolean
  isDangerous?: boolean
  clearSelection?: boolean
  refreshTime?: number
}[]

export type TPresets = {
  label: string
  filter?: GridFilterModel
  sort?: GridSortModel
}[]

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    marginRight: theme.spacing(1),
    color: 'inherit'
  },
  icon: {
    minWidth: theme.spacing(4)
  },
  paper: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider
  }
}))

export default function DataGridToolbar({
  selectionModel,
  bulkActions = [],
  presets = [],
  makeRequest,
  setFilter,
  setSort,
  setSelection
}: {
  selectionModel: string[]
  bulkActions: TBulkActions
  presets: TPresets
  makeRequest: () => void
  setFilter: React.Dispatch<React.SetStateAction<GridFilterModel | null>>
  setSort: React.Dispatch<React.SetStateAction<GridSortModel | null>>
  setSelection: React.Dispatch<React.SetStateAction<GridRowId[]>>
}) {
  const classes = useStyles()

  const [bulkAnchorEl, setBulkAnchorEl] = React.useState<null | HTMLElement>(
    null
  )
  const [presetAnchorEl, setPresetAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const handleBulkClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBulkAnchorEl(event.currentTarget)
  }

  const handlePresetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPresetAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Grid sx={{ p: 1 }} container justifyContent="space-between">
        <Grid item>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton size="medium" />
          <GridToolbarExport />

          {presets && presets.length > 0 && (
            <>
              <Button
                startIcon={<BsLightning />}
                aria-controls="preset-menu"
                aria-haspopup="true"
                onClick={handlePresetClick}
              >
                Presets
              </Button>

              <Menu
                id="preset-menu"
                anchorEl={presetAnchorEl}
                keepMounted
                open={Boolean(presetAnchorEl)}
                onClose={() => setPresetAnchorEl(null)}
                elevation={2}
                classes={{
                  paper: classes.paper
                }}
              >
                {presets.map(({ label, filter, sort }) => (
                  <MenuItem
                    key={`preset-${label}`}
                    onClick={() => {
                      filter && setFilter(filter)
                      sort && setSort(sort)

                      setPresetAnchorEl(null)
                    }}
                  >
                    <Typography noWrap variant="inherit">
                      {label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {bulkActions && bulkActions.length === 1 && (
            <Button
              disabled={selectionModel.length < 1}
              startIcon={bulkActions[0].icon}
              onClick={() => {
                bulkActions[0].action(selectionModel).then(async () => {
                  await setBulkAnchorEl(null)

                  bulkActions[0].clearSelection && setSelection([])

                  setTimeout(
                    () => bulkActions[0].autoRefresh && makeRequest(),
                    bulkActions[0].refreshTime
                  )
                })
              }}
            >
              {bulkActions[0].text}
            </Button>
          )}
        </Grid>
        <Grid item>
          {bulkActions && bulkActions.length > 1 && (
            <>
              <Button
                aria-controls="bulk-action-menu"
                aria-haspopup="true"
                endIcon={<MoreVert />}
                onClick={handleBulkClick}
                disabled={selectionModel && selectionModel.length < 1}
              >
                Bulk Actions
              </Button>

              <Menu
                id="bulk-action-menu"
                anchorEl={bulkAnchorEl}
                keepMounted
                open={Boolean(bulkAnchorEl)}
                onClose={() => setBulkAnchorEl(null)}
                elevation={2}
                classes={{
                  paper: classes.paper
                }}
              >
                {bulkActions.map(
                  ({
                    text,
                    icon,
                    action,
                    autoRefresh = true,
                    clearSelection = true,
                    refreshTime = 500
                  }) => (
                    <MenuItem
                      key={`bulk-action-${text}`}
                      onClick={() => {
                        action(selectionModel).then(async () => {
                          await setBulkAnchorEl(null)

                          clearSelection && setSelection([])

                          setTimeout(
                            () => autoRefresh && makeRequest(),
                            refreshTime
                          )
                        })
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        {icon}
                      </ListItemIcon>
                      <Typography noWrap variant="inherit">
                        {text}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </>
          )}
        </Grid>
      </Grid>

      <Divider light />
    </>
  )
}
