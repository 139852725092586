import React from 'react'
import { Box, Chip, Grid, Stack, Tooltip } from '@mui/material'
import {
  DataGridProProps,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridColTypeDef,
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridStateColDef,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import { classifyTags } from './tags'
import { getLinuxDistIcon } from './icons'
import {
  Check,
  CheckCircle,
  CloudOffTwoTone,
  DoDisturb,
  Error,
  FilterList,
  Help,
  Warning
} from '@mui/icons-material'
import { GrUpgrade } from 'react-icons/gr'
import uniqid from 'uniqid'
import { IconButton } from 'gatsby-theme-material-ui'
import { navigate } from 'gatsby'
import { numberFormat } from './misc'
import { TResultStatus } from './api'
import { isHostOffline, isHostUnknown } from './hosts'

interface Props {
  response: any
  arrayKey?: string
}

export const quickColumn = ({
  field,
  headerName,
  width = 120,
  hide = true,
  type = 'enhancedString',
  ...rest
}: GridColDef): GridColDef => ({
  field,
  headerName:
    headerName ||
    field.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }),
  width,
  hide,
  type,
  ...rest
})

export function transformApiResponseToRows(props: Props) {
  let rows: GridRowsProp = []

  const { response, arrayKey } = props

  if (!response || response === undefined) return rows

  try {
    rows = arrayKey ? response.data[arrayKey] : response.data
  } catch (error) {
    console.warn(error)
    rows = []
  }

  return rows
}

export const filterItem = (
  columnField: string,
  operatorValue: string,
  value: string
): GridFilterItem => ({
  columnField,
  operatorValue,
  value
})

export const hostOsRenderer = (params: GridValueFormatterParams) => {
  if (!params.value) return ''

  const prettyName = params.value as string

  const OSIdent = prettyName.split(' ')

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>{getLinuxDistIcon(OSIdent[0])}</Grid>
      <Grid item>{params.value}</Grid>
    </Grid>
  )
}

export const hostActiveRenderer = (params: GridValueFormatterParams) =>
  params.value ? <Check /> : <DoDisturb />

export const sandflyActiveRenderer = (params: GridValueFormatterParams) =>
  params.value ? <Check /> : <DoDisturb />

export const digitRenderer = (params: GridValueFormatterParams) => {
  if (!params.value) return ''

  return params.value
}

export const upgradeNoticeRenderer = (params: GridRenderCellParams) => {
  if (params.row['data.masked'] === true) {
    return (
      <>
        <span className="DataGrid__upgrade-icon">
          <GrUpgrade className="stroke-reset" />
        </span>
        <span className="DataGrid__upgrade-text">{params.value}</span>
      </>
    )
  }

  return params.value
}

export const tagRenderer = (params: GridValueFormatterParams) => {
  const value = params.value as string[]
  if (!value || value.length < 1) return ''
  const { mitreTechnique, mitreTactic, misc } = classifyTags(value)

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      {[...misc, ...mitreTactic, ...mitreTechnique].map(({ label }) => (
        <Grid item key={label}>
          <Chip variant="outlined" label={label} />
        </Grid>
      ))}
    </Grid>
  )
}

export const chipRenderer = (params: GridValueFormatterParams) => {
  const value = params.value as string[]
  if (!value || value.length < 1) return ''

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      {value.map((label) => (
        <Grid item key={label}>
          <Chip variant="outlined" label={label} />
        </Grid>
      ))}
    </Grid>
  )
}

export const tooltipRenderer = (params: GridValueFormatterParams) => {
  const value = params.value as string
  if (!value) return ''

  return (
    <Tooltip title={value} arrow>
      <span
        style={{
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          overflow: 'hidden'
        }}
      >{`${value}`}</span>
    </Tooltip>
  )
}

export const hostStatusRenderer = (params: GridRowParams) => {
  const actions = []  
  const { id, results_error, results_alert, host_id } = params.row
  const hasErrors = results_error > 0
  const hasAlerts = results_alert > 0
  const isOffline = isHostOffline(params.row)
  const isUnknown = isHostUnknown(params.row)
  

  const handleResultsNav = (
    event: React.MouseEvent<HTMLButtonElement>,
    resultsType?: TResultStatus
  ) => {
    event.stopPropagation()

    const items = [
      {
        columnField: 'header.host_id',
        operatorValue: 'equals',
        value: host_id,
        id: 1
      }
    ]
    resultsType &&
      items.push({
        columnField: 'data.status',
        operatorValue: 'is',
        value: resultsType,
        id: 2
      })

    navigate('/results/all/', {
      state: {
        requestParams: {
          filter: {
            items,
            linkOperator: 'and' as GridLinkOperator
          }
        }
      }
    })
  }

  if (!hasErrors && !hasAlerts && !isOffline) {
    actions.push(
      <Tooltip title="Passing">
        <IconButton
          aria-label="View all results"
          size="small"
          onClick={(e) => handleResultsNav(e)}
        >
          <CheckCircle color="primary" />
        </IconButton>
      </Tooltip>
    )
  }

  if (hasAlerts) {
    actions.push(
      <Tooltip title="Has Alerts">
        <IconButton
          aria-label="View alert results"
          size="small"
          onClick={(e) => handleResultsNav(e, 'alert')}
        >
          <Error color="error" />
        </IconButton>
      </Tooltip>
    )
  }

  if (isOffline && !isUnknown) {
    actions.push(
      <Tooltip title="Is Offline">
        <IconButton
          aria-label="View host"
          size="small"
          to={`/hosts/host/${id}/`}
        >
          <CloudOffTwoTone color="warning" />
        </IconButton>
      </Tooltip>
    )
  }

  if (hasErrors) {
    actions.push(
      <Tooltip title="Has Errors">
        <IconButton
          aria-label="View error results"
          size="small"
          onClick={(e) => handleResultsNav(e, 'error')}
        >
          <Warning color="warning" />
        </IconButton>
      </Tooltip>
    )
  }

  if (isUnknown) {
    actions.push(
      <Tooltip title="Is Unknown">
        <IconButton
          aria-label="View host"
          size="small"
          to={`/hosts/host/${id}/`}
        >
          <Help color="disabled" />
        </IconButton>
      </Tooltip>
    )
  }

  return actions.slice(0, 2)
}

export const statusRenderer = (params: GridValueFormatterParams) => {
  const value = params.value as string

  switch (value) {
    case 'pass':
      return (
        <Tooltip title="Passing">
          <CheckCircle color="primary" />
        </Tooltip>
      )
    case 'alert':
      return (
        <Tooltip title="Has Alerts">
          <Error color="error" />
        </Tooltip>
      )
    case 'error':
      return (
        <Tooltip title="Has Errors">
          <Warning color="warning" />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Unknown">
          <Help color="disabled" />
        </Tooltip>
      )
  }
}

export const alertCountRenderer = (params: GridRenderCellParams) => {
  const { host_id } = params.row
  const value = params.value as number
  return value > 0 ? (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <IconButton
        aria-label="View Alert Results"
        size="small"
        color="error"
        onClick={(e) => {
          e.stopPropagation()

          navigate('/results/all/', {
            state: {
              requestParams: {
                filter: {
                  items: [
                    {
                      columnField: 'header.host_id',
                      operatorValue: 'equals',
                      value: host_id,
                      id: 1
                    },
                    {
                      columnField: 'data.status',
                      operatorValue: 'is',
                      value: 'alert',
                      id: 2
                    }
                  ],
                  linkOperator: 'and' as GridLinkOperator
                }
              }
            }
          })
        }}
      >
        <FilterList />
      </IconButton>
      <Box color="error.main" fontWeight={600}>
        {numberFormat(value)}
      </Box>
    </Stack>
  ) : (
    <Box>{value}</Box>
  )
}

export const errorCountRenderer = (params: GridRenderCellParams) => {
  const { host_id } = params.row
  const value = params.value as number
  return value > 0 ? (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <IconButton
        aria-label='View Error Results'
        size="small"
        color="warning"
        onClick={(e) => {
          e.stopPropagation()

          navigate('/results/all/', {
            state: {
              requestParams: {
                filter: {
                  items: [
                    {
                      columnField: 'header.host_id',
                      operatorValue: 'equals',
                      value: host_id,
                      id: 1
                    },
                    {
                      columnField: 'data.status',
                      operatorValue: 'is',
                      value: 'error',
                      id: 2
                    }
                  ],
                  linkOperator: 'and' as GridLinkOperator
                }
              }
            }
          })
        }}
      >
        <FilterList />
      </IconButton>
      <Box color="warning.main" fontWeight={600}>
        {numberFormat(value)}
      </Box>
    </Stack>
  ) : (
    <Box>{value}</Box>
  )
}

export const blankFilter: GridFilterModel = {
  items: [],
  linkOperator: 'and' as GridLinkOperator
}

export const getSimpleSelectStrings = (key: string, rows: GridRowsProp) => {
  try {
    return [...new Set(rows.map((row) => row[key]))]
  } catch (error) {
    console.log(error)
    return []
  }
}

const stringOperators = getGridStringOperators()

export const enhancedStringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: [
    ...stringOperators,
    {
      value: 'notContains',
      label: 'not contains',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        _column: GridStateColDef
      ) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null
        }

        return (params: GridCellParams): boolean => {
          return (
            typeof params.value === 'string' &&
            params.value.indexOf(filterItem.value) === -1
          )
        }
      },
      InputComponent: stringOperators[0].InputComponent
    },
    {
      value: 'notEquals',
      label: 'not equals',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        _column: GridStateColDef
      ) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null
        }

        return (params: GridCellParams): boolean => {
          return (
            typeof params.value === 'string' &&
            params.value !== filterItem.value
          )
        }
      },
      InputComponent: stringOperators[0].InputComponent
    },
    {
      value: 'notStartsWith',
      label: 'not starts with',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        _column: GridStateColDef
      ) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null
        }

        return (params: GridCellParams): boolean => {
          return (
            typeof params.value === 'string' &&
            !params.value.startsWith(filterItem.value)
          )
        }
      },
      InputComponent: stringOperators[0].InputComponent
    },
    {
      value: 'notEndsWith',
      label: 'not ends with',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        _column: GridStateColDef
      ) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null
        }

        return (params: GridCellParams): boolean => {
          return (
            typeof params.value === 'string' &&
            !params.value.endsWith(filterItem.value)
          )
        }
      },
      InputComponent: stringOperators[0].InputComponent
    }
  ]
}

export function upgradeStringFilter(columns: DataGridProProps['columns']) {
  return columns.map((column) =>
    column.type
      ? column
      : {
          ...column,
          type: 'enhancedString'
        }
  )
}

export const filterModelDecorator = (filterModel: GridFilterModel) => ({
  ...filterModel,
  items:
    filterModel?.items.map((item) =>
      item?.id
        ? item
        : {
            ...item,
            id: uniqid.time()
          }
    ) || []
})
