import { TFeatureBooleans, TFeatureKey, TFeatureLimits } from '@utils/api'
import { useContext } from 'react'
import LicenseContext from '@utils/contexts/LicenseContext'
import { isLoggedIn } from '@utils/auth'

/**
 * Provides user and license data
 */
const useFeatures = () => {
  const { loading, license } = useContext(LicenseContext)
  const featuresWithLimits = license ? Object.keys(license.limits) : []

  const canUseFeature = (id: TFeatureKey): boolean => {
    const loggedIn = isLoggedIn()
    if (!loggedIn || !license) return true

    const { usage, limits } = license
    const hasLimit = featuresWithLimits.includes(id)
    const hasUsage = hasLimit && usage && Object.keys(usage).includes(id)

    if (hasLimit && hasUsage) {
      if (limits[id as TFeatureLimits] === -1) return true
      return (
        usage !== null &&
        usage[id as TFeatureLimits] < limits[id as TFeatureLimits]
      )
    } else {
      if (license === null || license.limits.features === null) {
        return false
      } else {
        return license.limits.features.includes(id as TFeatureBooleans) || false
      }
    }
  }

  return {
    loading,
    canUseFeature
  }
}

export default useFeatures
