import React, { ReactElement, useState } from 'react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/fold/foldgutter.css'
import loadable from '@loadable/component'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileCopy } from '@mui/icons-material'
import { Skeleton, Box, Button } from '@mui/material'

const CodeMirror = loadable(() => import('./CodeControlled'))
export interface CodeProps {
  value?: string
  readOnly?: boolean
  copyButton?: boolean
  height?: React.CSSProperties['height']
  loading?: boolean
  onValueChange?: (value: string) => void
}

export default function Code({
  value = '',
  readOnly = true,
  copyButton = true,
  height,
  loading = false,
  onValueChange,
  ...codeMirrorProps
}: CodeProps): ReactElement {
  const [copied, setCopied] = useState<boolean>(false)

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        background: (theme) => theme.palette.background.paper,
        '& .CodeMirror': {
          height: height || '40rem',
          background: (theme) => theme.palette.background.paper,
          color: 'text.primary',
          fontSize: '0.875rem',

          '& .CodeMirror-cursor': {
            borderColor: 'currentColor'
          },

          '& .cm-string': {
            color: 'text.secondary'
          },
          '& .cm-property': {
            color: 'text.secondary'
          },
          '& .cm-atom,& .cm-number': {
            color: 'warning.dark'
          },
          '& .cm-focused .cm-cursor': {
            borderLeftColor: '#0e9'
          },
          '& .cm-focused .cm-selectionBackground,& ::selection': {
            backgroundColor: '#074'
          },
          '& .CodeMirror-gutters': {
            backgroundColor: (theme) => theme.palette.background.default,
            color: 'text.hint',
            borderColor: (theme) => theme.palette.divider
          }
        }
      }}
    >
      {loading ? (
        <Skeleton height={height || '40rem'} />
      ) : (
        <>
          {copyButton && (
            <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
              <Button
                sx={{
                  position: 'absolute',
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(2),
                  zIndex: 1000,
                  '& :disabled': {
                    opacity: 0.65
                  }
                }}
                disabled={copied}
                endIcon={<FileCopy />}
              >
                {!copied ? 'Copy' : 'Copied'}
              </Button>
            </CopyToClipboard>
          )}
          <CodeMirror
            className="code-mirror"
            value={value}
            options={{
              mode: { name: 'javascript', json: true },
              readOnly,
              theme: 'sandfly',
              lineNumbers: true,
              foldGutter: true,
              lineWrapping: true,
              gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
              ...codeMirrorProps
            }}
            onBeforeChange={(_editor: any, _data: any, value: string) => {
              !readOnly && onValueChange && value && onValueChange(value)
            }}
            onChange={() => {}}
          />
        </>
      )}
    </Box>
  )
}
