import {
  Box,
  ButtonGroup,
  Divider,
  Grid,
  PaperProps,
  Stack,
  Typography
} from '@mui/material'
import React, { FC, ReactChild } from 'react'
import { Surface } from '@components'
import { SurfaceProps } from './Surface'
import useFeatures from '@utils/hooks/useFeatures'
import { GrUpgrade } from 'react-icons/gr'
import { TFeatureKey } from '@utils/api'

interface Props extends PaperProps {
  heading?: string
  actions?: ReactChild | null | false
  headingButton?: ReactChild | Element | null | false
  className?: string
  color?: SurfaceProps['color']
  disabled?: boolean
  feature?: TFeatureKey
}

const Card: FC<Props> = ({
  heading,
  actions,
  headingButton,
  children,
  className,
  feature,
  ...rest
}) => {
  const { canUseFeature } = useFeatures()
  const canUse = feature ? canUseFeature(feature) : true

  return (
    <Surface {...rest}>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        sx={{
          flexGrow: 1,
          height: '100%',
          '& .card__restricted-icon': {
            color: 'secondary.main',
            mr: 1,
            verticalAlign: 'middle',

            '& path': {
              stroke: 'currentColor'
            }
          }
        }}
      >
        {(heading || headingButton) && (
          <Grid item>
            <Stack direction="row" justifyContent="space-between" px={2} py={1}>
              {heading && (
                <Typography
                  variant="overline"
                  component="h2"
                  style={{ margin: 0 }}
                >
                  {!canUse && (
                    <span className="card__restricted-icon">
                      <GrUpgrade />
                    </span>
                  )}
                  {heading}
                </Typography>
              )}
              <ButtonGroup color="inherit">{headingButton}</ButtonGroup>
            </Stack>

            <Divider />
          </Grid>
        )}
        <Grid item>{children}</Grid>

        {actions && (
          <Grid item style={{ marginBottom: 0, marginTop: 'auto' }}>
            <Box p={2} pt={0} textAlign="right">
              {actions}
            </Box>
          </Grid>
        )}
      </Grid>
    </Surface>
  )
}

export default Card
