import React, { useState } from 'react'
import {
  TextField,
  IconButton,
  InputAdornment,
  TextFieldProps
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { TTextField } from './TextField'

const TextFieldPassword: React.FC<TTextField> = ({ form, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const controlledProps = form
    ? {
        value: form.values[props.id],
        error: form.errors[props.id],
        onChange: (
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => form.setFieldValue(props.id, event.currentTarget.value),
        onBlur: () => form.validateField(props.id)
      }
    : {}
  const defaults: TextFieldProps = {
    id: 'password',
    fullWidth: true,
    type: showPassword ? 'text' : 'password',
    label: 'Password',
    variant: 'outlined',
    InputLabelProps: {
      shrink: true
    },
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
    }
  }

  const config = { ...defaults, ...controlledProps, ...props }

  return <TextField {...config} />
}

export default TextFieldPassword
